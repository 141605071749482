import { GENERAL_CONFIG } from '../../config';
import {
    SET_INTERVIEWS,
    SET_INTERVIEWS_ERROR,
    CLEAR_INTERVIEWS,
    SET_INTERVIEW,
    CLEAR_INTERVIEW,
    SET_INTERVIEW_ERROR,
    SET_INTERVIEW_CREATING,
} from '../actionTypes';

const initialState = {
    interviews: null,
    pagesCount: 0,
    activePage: 1,
    searchValue: '',
    loading: true,
    error: '',
    interview: null,
    interviewLoading: true,
    interviewError: '',
    interviewCreating: false,
};

const interviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INTERVIEWS:
            const pagesCount = Math.ceil(action.interviewsCount / GENERAL_CONFIG.pagination);
            return {
                ...state,
                interviews: action.interviews,
                pagesCount: pagesCount,
                activePage: action.page,
                searchValue: action.searchValue,
                loading: false,
                error: '',
            };
        case SET_INTERVIEWS_ERROR:
            return {
                ...state,
                interviews: null,
                pagesCount: 0,
                activePage: 1,
                searchValue: '',
                loading: false,
                error: action.error,
            };
        case CLEAR_INTERVIEWS:
            return {
                ...state,
                interviews: null,
                pagesCount: 0,
                activePage: 1,
                searchValue: '',
                loading: true,
                error: '',
            };
        case SET_INTERVIEW:
            return {
                ...state,
                interview: action.interview,
                interviewLoading: false,
                interviewError: '',
            };
        case SET_INTERVIEW_ERROR:
            return {
                ...state,
                interview: null,
                interviewLoading: false,
                interviewError: action.error,
            };
        case SET_INTERVIEW_CREATING:
            return {
                ...state,
                interviewCreating: action.value,
            };
        case CLEAR_INTERVIEW:
            return {
                ...state,
                interview: null,
                interviewLoading: true,
                interviewError: '',
            };
        default:
            return state;
    }
};

export default interviewReducer;
