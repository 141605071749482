import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Container, Card, Typography, Button } from '@material-ui/core';
import FormElement from '../shared/FormElement';
import { changePassword } from '../../redux/auth/authActions';

function ChangePassword() {
    const dispatch = useDispatch();

    const initialValues = {
        old_password: '',
        password: '',
        confirm_password: '',
    };

    const validationSchema = Yup.object({
        old_password: Yup.string()
            .required('This field is required')
            .min(6, 'Password must contain at least 6 characters'),
        password: Yup.string()
            .required('This field is required')
            .min(6, 'Password must contain at least 6 characters'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), ''], "Passwords don't match")
            .required('This field is required')
            .min(6, 'Password must contain at least 6 characters'),
    });

    const onSubmit = async (values, { setErrors }) => {
        const res = await dispatch(changePassword(values.old_password, values.password));

        if (res && res.status === 400 && res.data && res.data.message) {
            let message = res.data && res.data.message;
            setErrors({ old_password: message });
        }
    };

    return (
        <div className="auth-container">
            <Container maxWidth="sm">
                <div className="text-center mb-4">
                    <Link to="/" className="logo">
                        <img src="/images/logo.svg" alt="Tegus" />
                    </Link>
                </div>

                <Card>
                    <div className="auth-content">
                        <Typography variant="h4" component="h2" align="center">
                            Change Password
                        </Typography>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {(formik) => {
                                return (
                                    <Form className="mt-8">
                                        <div className="mb-8">
                                            <FormElement
                                                control="input"
                                                type="password"
                                                label="Temporary Password"
                                                name="old_password"
                                            />
                                        </div>

                                        <div className="mb-8">
                                            <FormElement
                                                control="input"
                                                type="password"
                                                label="New Password"
                                                name="password"
                                            />
                                        </div>

                                        <div className="mb-8">
                                            <FormElement
                                                control="input"
                                                type="password"
                                                label="Confirm New Password"
                                                name="confirm_password"
                                            />
                                        </div>

                                        {formik.errors.wrong_token && (
                                            <p className="form-error">
                                                {formik.errors.wrong_token}
                                            </p>
                                        )}

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            type="submit"
                                            formNoValidate
                                            // disabled={!formik.isValid || formik.isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </Card>
            </Container>
        </div>
    );
}

export default ChangePassword;
