import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Card,
    Button,
    Box,
    Breadcrumbs,
    Typography,
    Link as LinkElement,
    Tooltip,
} from '@material-ui/core';
import FormElement from '../shared/FormElement';
import { createUser } from '../../redux/users/userActions';

function AddUser(props) {
    const dispatch = useDispatch();
    const formRef = useRef();

    useHotkeys('esc', (e) => {
        e.preventDefault();
        props.history.push('/users');
    });

    useHotkeys('ctrl+s', (e) => {
        e.preventDefault();
        formRef.current.submitForm();
    });

    const initialValues = {
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
    };

    const validationSchema = Yup.object({
        company_name: Yup.string().required('This field is required'),
        first_name: Yup.string().required('This field is required'),
        last_name: Yup.string().required('This field is required'),
        email: Yup.string().email('Invalid email format').required('This field is required'),
    });

    const onSubmit = async (values, { setErrors }) => {
        const res = await dispatch(createUser(values, props.history));

        if (res && res.status === 400 && res.data && res.data.email) {
            let message = res.data && res.data.email && res.data.email[0];
            setErrors({ email_unique: message });
        }
    };

    return (
        <Container maxWidth="sm" className="mt-2">
            <Breadcrumbs separator="›" className="mb-3">
                <LinkElement component={Link} to="/users" color="primary">
                    Users
                </LinkElement>
                <Typography color="textPrimary">Add User</Typography>
            </Breadcrumbs>

            <Card className="px-8 pt-6 pb-8">
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(formik) => {
                        return (
                            <Form className="mt-6">
                                <div className="mb-6">
                                    <FormElement
                                        control="input"
                                        label="Company Name"
                                        name="company_name"
                                        size="small"
                                    />
                                </div>

                                <div className="mb-6">
                                    <FormElement
                                        control="input"
                                        label="Name"
                                        name="first_name"
                                        size="small"
                                    />
                                </div>

                                <div className="mb-6">
                                    <FormElement
                                        control="input"
                                        label="Surname"
                                        name="last_name"
                                        size="small"
                                    />
                                </div>

                                <div className="mb-8">
                                    <FormElement
                                        control="input"
                                        type="email"
                                        label="Email address"
                                        name="email"
                                        size="small"
                                    />
                                </div>

                                {formik.errors.email_unique && (
                                    <p className="form-error">{formik.errors.email_unique}</p>
                                )}

                                <Box className="text-center mt-3" textAlign="center">
                                    <Tooltip title="esc" placement="top">
                                        <Button
                                            component={Link}
                                            to="/users"
                                            variant="outlined"
                                            color="primary"
                                            className="text-capitalize mx-2 width-md"
                                        >
                                            Cancel
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="ctr + s" placement="top">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className="text-capitalize mx-2 width-md"
                                            formNoValidate
                                            // disabled={!formik.isValid || formik.isSubmitting}
                                        >
                                            Save
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Card>
        </Container>
    );
}

export default AddUser;
