// auth actions
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_AUTH_SUCCESS = 'SET_AUTH_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const FORGOT_PASSWORD_SENT = 'FORGOT_PASSWORD_SENT';
export const RESET_PASSWORD_SENT = 'RESET_PASSWORD_SENT';
export const ACTIVATE_ENABLED = 'ACTIVATE_ENABLED';

// user actions
export const SET_USERS = 'SET_USERS';
export const SET_USERS_ERROR = 'SET_USERS_ERROR';
export const CLEAR_USERS = 'CLEAR_USERS';
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_USER_ERROR = 'SET_USER_ERROR';

// expert actions
export const SET_EXPERTS = 'SET_EXPERTS';
export const SET_EXPERTS_ERROR = 'SET_EXPERTS_ERROR';
export const CLEAR_EXPERTS = 'CLEAR_EXPERTS';
export const SET_EXPERT = 'SET_EXPERT';
export const SET_EXPERT_ERROR = 'SET_EXPERT_ERROR';
export const CLEAR_EXPERT = 'CLEAR_EXPERT';
export const SET_EXPERTS_LIST = 'SET_EXPERTS_LIST';
export const CLEAR_EXPERTS_LIST = 'CLEAR_EXPERTS_LIST';

// interview actions
export const SET_INTERVIEWS = 'SET_INTERVIEWS';
export const SET_INTERVIEWS_ERROR = 'SET_INTERVIEWS_ERROR';
export const CLEAR_INTERVIEWS = 'CLEAR_INTERVIEWS';
export const SET_INTERVIEW = 'SET_INTERVIEW';
export const SET_INTERVIEW_ERROR = 'SET_INTERVIEW_ERROR';
export const CLEAR_INTERVIEW = 'CLEAR_INTERVIEW';
export const SET_INTERVIEW_CREATING = 'SET_INTERVIEW_CREATING';

// analysis actions
export const SET_ANALYSES = 'SET_ANALYSES';
export const SET_ANALYSES_ERROR = 'SET_ANALYSES_ERROR';
export const CLEAR_ANALYSES = 'CLEAR_ANALYSES';
export const SET_ANALYSIS = 'SET_ANALYSIS';
export const SET_ANALYSIS_ERROR = 'SET_ANALYSIS_ERROR';
export const CLEAR_ANALYSIS = 'CLEAR_ANALYSIS';
export const SET_ANALYSIS_CREATING = 'SET_ANALYSIS_CREATING';
