import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import { format, parseISO } from 'date-fns';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Card,
    Button,
    Box,
    Grid,
    IconButton,
    Tooltip,
    Breadcrumbs,
    Typography,
    Link as LinkElement,
    FormHelperText,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AddBox, Edit, CheckBox } from '@material-ui/icons';
import FormElement from '../shared/FormElement';
import Loader from '../shared/Loader';
import { getHtmlBody } from '../../helpers/Functons';
import {
    getInterview,
    clearInterview,
    editInterview,
    getDocHtml,
} from '../../redux/interviews/interviewActions';
import {
    getExpertsList,
    clearExpertsList,
    getExpert,
    clearExpert,
} from '../../redux/experts/expertActions';

function EditInterview(props) {
    const dispatch = useDispatch();
    const formRef = useRef();
    const docRef = useRef();
    const [docContentEditable, setDocContentEditable] = useState(false);
    const expertsList = useSelector((state) => state.experts.expertsList);
    const expert = useSelector((state) => state.experts.expert);
    const interviewCreating = useSelector((state) => state.interviews.interviewCreating);
    const interview = useSelector((state) => state.interviews.interview);
    const interviewError = useSelector((state) => state.interviews.interviewError);
    const history = props.history;
    const id = props.match.params.id;
    let experts = useMemo(() => (expertsList ? expertsList : []), [expertsList]);

    useEffect(() => {
        dispatch(getExpertsList());
        dispatch(getInterview(id));

        return () => {
            dispatch(clearExpert());
            dispatch(clearExpertsList());
            dispatch(clearInterview());
        };
    }, [dispatch, id]);

    useEffect(() => {
        if (interview && interview.expert) {
            dispatch(getExpert(interview.expert.id));
            if (interview.expert.is_deleted) {
                experts.push(interview.expert);
            }
        }
    }, [dispatch, interview, experts]);

    useHotkeys('esc', (e) => {
        e.preventDefault();
        props.history.push('/interviews');
    });

    useHotkeys('ctrl+s', (e) => {
        e.preventDefault();
        formRef.current.submitForm();
    });

    const initialValues = {
        primary_tickers: ' ',
        expert: null,
        headline: ' ',
        qualification_questions: ' ',
        document_html: ' ',
        date: new Date(),
        rating: ' ',
    };

    const interviewValues = interview
        ? {
              primary_tickers: interview.primary_tickers,
              expert: interview.expert,
              headline: interview.headline,
              qualification_questions: interview.qualification_questions,
              document_html: interview.document_html,
              date: interview.date,
              rating: interview.rating,
          }
        : null;

    const defaultValues = interviewValues ? interviewValues : initialValues;

    const validationSchema = Yup.object({
        primary_tickers: Yup.string().required('This field is required'),
        expert: Yup.object().required('This field is required').nullable(),
        headline: Yup.string().required('This field is required'),
        qualification_questions: Yup.string().required('This field is required'),
        document_html: Yup.string().required('This field is required'),
        date: Yup.date()
            .typeError('Invalid date. Please use this format: yyyy-dd-mm')
            .required('This field is required'),
        rating: Yup.number()
            .max(10, 'Maximum value is 10')
            .typeError('Please enter a valid number')
            .integer('Please enter a valid number')
            .positive('Please enter a positive number')
            .required('This field is required'),
    });

    const onSubmit = (values) => {
        let {
            primary_tickers,
            expert,
            headline,
            qualification_questions,
            document_html,
            date,
            rating,
        } = values;
        let formattedDate =
            typeof date === 'string'
                ? format(parseISO(date), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
                : format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

        let data = {
            primary_tickers,
            expert: expert.id,
            headline,
            qualification_questions,
            document_html,
            date: formattedDate,
            rating,
        };

        dispatch(editInterview(id, data, history));
    };

    const fileChanged = async (file) => {
        setDocContentEditable(false);
        const formData = new FormData();
        formData.append('document_file', file);
        dispatch(getDocHtml(formData));

        const res = await dispatch(getDocHtml(formData));

        if (res && res.status === 200 && res.data && res.data.document_html) {
            let new_html = getHtmlBody(res.data.document_html);
            formRef.current.values.document_html = new_html;
            docRef.current.innerHTML = new_html;
        }

        if (res && res.status === 400 && res.data && res.data.file) {
            let message = res.data && res.data.file && res.data.file[0];
            formRef.current.setErrors({ file_format: message });
        }
    };

    const onExpertSelected = (expert) => {
        if (expert) {
            dispatch(getExpert(expert.id));
        } else {
            dispatch(clearExpert());
        }
    };

    return (
        <>
            <Container maxWidth="md" className="mt-2">
                <Breadcrumbs separator="›" className="mb-3">
                    <LinkElement component={Link} to="/interviews" color="primary">
                        Interviews
                    </LinkElement>
                    <Typography color="textPrimary">Edit Interview</Typography>
                </Breadcrumbs>

                <Card className="px-8 pt-6 pb-8">
                    {interviewError ? (
                        <Alert severity="error" className="bordered">
                            Something went wrong!
                        </Alert>
                    ) : (
                        <Formik
                            innerRef={formRef}
                            initialValues={defaultValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            enableReinitialize={true}
                        >
                            {(formik) => {
                                return (
                                    <Form className="mt-6">
                                        <div className="mb-6">
                                            <FormElement
                                                control="input"
                                                label="Primary Companies"
                                                name="primary_tickers"
                                                size="small"
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <Box display="flex" alignItems="flex-start">
                                                <Box flexGrow="1" mr={1}>
                                                    <FormElement
                                                        control="select"
                                                        label="Expert Linked"
                                                        name="expert"
                                                        size="small"
                                                        options={experts}
                                                        labelName="full_name"
                                                        noOptionsText={'No Expert Found'}
                                                        onChangeEvent={onExpertSelected}
                                                    />
                                                </Box>

                                                <IconButton
                                                    component={Link}
                                                    to="/experts/add"
                                                    color="primary"
                                                    className="p-1"
                                                >
                                                    <Tooltip title="Add Expert" placement="top">
                                                        <AddBox style={{ fontSize: 30 }} />
                                                    </Tooltip>
                                                </IconButton>
                                            </Box>
                                            {formik.values.expert &&
                                                formik.values.expert.is_deleted && (
                                                    <FormHelperText className="ml-3">
                                                        *This expert is deleted
                                                    </FormHelperText>
                                                )}
                                            {expert && (
                                                <Box fontStyle="italic" mt={2} fontSize={13}>
                                                    <Box fontWeight="fontWeightBold">
                                                        Expert Bio:
                                                    </Box>
                                                    <Truncate lines={3}>{expert.bio}</Truncate>
                                                </Box>
                                            )}
                                        </div>

                                        <div className="mb-6">
                                            <FormElement
                                                control="input"
                                                label="Interview Headline"
                                                name="headline"
                                                size="small"
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <FormElement
                                                control="textarea"
                                                label="Qualification Questions"
                                                name="qualification_questions"
                                                rows={6}
                                            />
                                        </div>

                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <div className="mb-5">
                                                    <FormElement
                                                        control="datepicker"
                                                        label="Interview Date"
                                                        name="date"
                                                        size="small"
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="mb-5">
                                                    <FormElement
                                                        control="input"
                                                        label="Interview Rating (1-10)"
                                                        name="rating"
                                                        size="small"
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <div className="mb-6">
                                            <Box display="flex" justifyContent="flex-end">
                                                {docContentEditable ? (
                                                    <Tooltip title="Save Text" placement="top">
                                                        <IconButton
                                                            style={{ color: 'green' }}
                                                            onClick={() => {
                                                                formik.setFieldValue(
                                                                    'document_html',
                                                                    docRef.current.innerHTML,
                                                                );
                                                                setDocContentEditable(false);
                                                            }}
                                                        >
                                                            <CheckBox />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Edit Text" placement="top">
                                                        <IconButton
                                                            color="secondary"
                                                            onClick={() =>
                                                                setDocContentEditable(true)
                                                            }
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}

                                                <FormElement
                                                    accept=".doc, .docx"
                                                    control="fileuploadsm"
                                                    name="file"
                                                    color="secondary"
                                                    tooltipText="Upload New"
                                                    fileChanged={fileChanged}
                                                />
                                            </Box>

                                            {formik.errors.file_format && (
                                                <p className="error text-right mb-2">
                                                    {formik.errors.file_format}
                                                </p>
                                            )}

                                            <Card
                                                variant="outlined"
                                                className={`with-label ${
                                                    docContentEditable && 'focused'
                                                }`}
                                            >
                                                <label className="card-label">Interview</label>
                                                <div
                                                    ref={docRef}
                                                    className="interview-doc py-4 px-3"
                                                    contentEditable={docContentEditable}
                                                    dangerouslySetInnerHTML={{
                                                        __html: getHtmlBody(
                                                            formik.values.document_html,
                                                        ),
                                                    }}
                                                />
                                            </Card>
                                        </div>

                                        <Box className="text-center mt-3" textAlign="center">
                                            <Tooltip title="esc" placement="top">
                                                <Button
                                                    component={Link}
                                                    to="/interviews"
                                                    variant="outlined"
                                                    color="primary"
                                                    className="text-capitalize mx-2 width-md"
                                                >
                                                    Cancel
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="ctr + s" placement="top">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    className="text-capitalize mx-2 width-md"
                                                    formNoValidate
                                                    // disabled={!formik.isValid || formik.isSubmitting}
                                                >
                                                    Save
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </Card>
            </Container>

            {interviewCreating && <Loader />}
        </>
    );
}

export default EditInterview;
