import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Container,
    Card,
    Box,
    Typography,
    Divider,
    CircularProgress,
    Breadcrumbs,
    Link as LinkElement,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { format, parseISO } from 'date-fns';
import StarRating from '../shared/StarRating';
import { getHtmlBody } from '../../helpers/Functons';
import { getInterview, clearInterview } from '../../redux/interviews/interviewActions';

function Interview(props) {
    const dispatch = useDispatch();
    const interview = useSelector((state) => state.interviews.interview);
    const interviewLoading = useSelector((state) => state.interviews.interviewLoading);
    const interviewError = useSelector((state) => state.interviews.interviewError);
    const id = props.match.params.id;

    useEffect(() => {
        dispatch(getInterview(id));

        return () => {
            dispatch(clearInterview());
        };
    }, [dispatch, id]);

    return (
        <Container maxWidth="md" className="mt-2">
            <Breadcrumbs separator="›" className="mb-3">
                <LinkElement component={Link} to="/interviews" color="primary">
                    Interviews
                </LinkElement>
                <Typography color="textPrimary">Interview Details</Typography>
            </Breadcrumbs>

            <Card className="px-8 pt-6 pb-8">
                {interviewLoading ? (
                    <Box display="flex" justifyContent="center" p={4}>
                        <CircularProgress size={50} />
                    </Box>
                ) : interviewError ? (
                    <Alert severity="error" className="bordered">
                        Something went wrong!
                    </Alert>
                ) : (
                    interview && (
                        <>
                            <div>
                                <Typography variant="h5" color="primary">
                                    {interview.headline}
                                </Typography>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mt={3}
                                >
                                    <Box fontWeight="bold">{interview.expert.full_name}</Box>
                                    <StarRating val={interview.rating} />
                                </Box>
                            </div>

                            <Divider className="my-5" />

                            <div className="mt-5">
                                <Typography variant="h6" color="secondary" className="mb-1">
                                    Primary Companies
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {interview.primary_tickers}
                                </Typography>
                            </div>

                            <Divider className="my-5" />

                            <div className="mt-5">
                                <Typography variant="h6" color="secondary" className="mb-1">
                                    Qualification Questions
                                </Typography>
                                <Typography variant="body2" component="div">
                                    <pre>{interview.qualification_questions}</pre>
                                </Typography>
                            </div>

                            <Divider className="my-5" />

                            <div className="mt-5">
                                <Typography variant="h6" color="secondary" className="mb-1">
                                    Interview
                                </Typography>
                                <Typography variant="body2" component="div">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: getHtmlBody(interview.document_html),
                                        }}
                                    ></div>
                                </Typography>
                            </div>

                            <Box fontSize={14} fontStyle="italic" mt={5} textAlign="right">
                                <Typography color="textSecondary">
                                    Date added: {format(parseISO(interview.date), 'yyyy-dd-MM')}
                                </Typography>
                            </Box>
                        </>
                    )
                )}
            </Card>
        </Container>
    );
}

export default Interview;
