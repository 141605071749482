import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import Textarea from './Textarea';
import Datepicker from './Datepicker';
import FileUpload from './FileUpload';
import FileUploadSm from './FileUploadSm';
import Select from './Select';
import List from './List';

function FormElement(props) {
    const { control, ...rest } = props;

    switch (control) {
        case 'input':
            return <Input {...rest} />;
        case 'textarea':
            return <Textarea {...rest} />;
        case 'datepicker':
            return <Datepicker {...rest} />;
        case 'fileupload':
            return <FileUpload {...rest} />;
        case 'fileuploadsm':
            return <FileUploadSm {...rest} />;
        case 'select':
            return <Select {...rest} />;
        case 'list':
            return <List {...rest} />;
        default:
            return null;
    }
}

FormElement.propTypes = {
    control: PropTypes.string,
};

export default FormElement;
