import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import { Card, Box, Button, IconButton } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';

function List(props) {
    const { name, label, form } = props;

    let listError = form.values[name].length === 0 && form.touched[name] && form.errors[name];
    let listIsInvalid = listError ? 'invalid' : '';

    return (
        <>
            <Card variant="outlined" className={`with-label pt-5 pb-3 px-3 ${listIsInvalid}`}>
                <label className="card-label">{label}</label>
                <FieldArray
                    name={name}
                    render={(arrayHelpers) => (
                        <div>
                            {form.values[name] &&
                                form.values[name].length > 0 &&
                                form.values[name].map((item, index) => {
                                    let error =
                                        form.touched[name] &&
                                        form.touched[name][index] &&
                                        form.errors[name] &&
                                        form.errors[name][index];
                                    let isInvalid = error ? 'invalid' : '';

                                    return (
                                        <div key={index} className="mb-3">
                                            <Box display="flex" alignItems="flex-start">
                                                <Box flexGrow="1" mr={1}>
                                                    <Field
                                                        name={`${name}.${index}`}
                                                        className={`form-input ${isInvalid}`}
                                                    />
                                                </Box>

                                                <IconButton
                                                    className="p-1 mt-1"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </Box>
                                            {error && <p className="error">{error}</p>}
                                        </div>
                                    );
                                })}
                            <div className="text-right">
                                <Button
                                    color="primary"
                                    className="text-capitalize"
                                    startIcon={<Add />}
                                    onClick={() => arrayHelpers.push('')}
                                >
                                    Add New
                                </Button>
                            </div>
                        </div>
                    )}
                />
            </Card>
            {listError && <p className="error">{listError}</p>}
        </>
    );
}

List.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.string,
};

export default List;
