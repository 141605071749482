import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Container, Card, Typography, Button, Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import FormElement from '../shared/FormElement';
import { resetPassword, setResetPasswordSent } from '../../redux/auth/authActions';

function ResetPassword(props) {
    const dispatch = useDispatch();
    const activation_token = props.match.params.reset_token;
    const resetPasswordSent = useSelector((state) => state.auth.resetPasswordSent);

    useEffect(() => {
        return () => {
            dispatch(setResetPasswordSent(false));
        };
    }, [dispatch]);

    const initialValues = {
        password: '',
        confirmPassword: '',
    };

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('This field is required')
            .min(6, 'Password must contain at least 6 characters'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), ''], "Passwords don't match")
            .required('This field is required')
            .min(6, 'Password must contain at least 6 characters'),
    });

    const onSubmit = async (values, { setErrors }) => {
        const res = await dispatch(resetPassword(activation_token, values.password));

        if (res && res.status === 400 && res.data && res.data.activation_token) {
            let message = res.data && res.data.activation_token && res.data.activation_token[0];
            setErrors({ wrong_token: message });
        }
    };

    return (
        <div className="auth-container">
            <Container maxWidth="sm">
                <div className="text-center mb-4">
                    <Link to="/" className="logo">
                        <img src="/images/logo.svg" alt="Tegus" />
                    </Link>
                </div>

                <Card>
                    <div className="auth-content">
                        <Typography variant="h4" component="h2" align="center">
                            Reset Password
                        </Typography>
                        {resetPasswordSent ? (
                            <Box pt={8} pb={4}>
                                <Alert severity="success" variant="outlined">
                                    <AlertTitle>Success</AlertTitle>Your password was changed.
                                    <br /> Go to Sign In page and try to Log In again.
                                </Alert>

                                <Box className="text-center mt-3" textAlign="center">
                                    <Button
                                        component={Link}
                                        to="/sign-in"
                                        color="primary"
                                        className="text-capitalize"
                                    >
                                        Sign In
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {(formik) => {
                                    return (
                                        <Form className="mt-8">
                                            <div className="mb-8">
                                                <FormElement
                                                    control="input"
                                                    type="password"
                                                    label="New Password"
                                                    name="password"
                                                />
                                            </div>

                                            <div className="mb-8">
                                                <FormElement
                                                    control="input"
                                                    type="password"
                                                    label="Confirm New Password"
                                                    name="confirmPassword"
                                                />
                                            </div>

                                            {formik.errors.wrong_token && (
                                                <p className="form-error">
                                                    {formik.errors.wrong_token}
                                                </p>
                                            )}

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                fullWidth
                                                type="submit"
                                                formNoValidate
                                                // disabled={!formik.isValid || formik.isSubmitting}
                                            >
                                                Submit
                                            </Button>

                                            <Box className="text-center mt-3" textAlign="center">
                                                <Button
                                                    component={Link}
                                                    to="/sign-in"
                                                    color="primary"
                                                    className="text-capitalize"
                                                >
                                                    Sign In
                                                </Button>
                                            </Box>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        )}
                    </div>
                </Card>
            </Container>
        </div>
    );
}

export default ResetPassword;
