import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import { format } from 'date-fns';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Card,
    Button,
    Box,
    Grid,
    IconButton,
    Tooltip,
    Breadcrumbs,
    Typography,
    Link as LinkElement,
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import FormElement from '../shared/FormElement';
import Loader from '../shared/Loader';
import { createInterview } from '../../redux/interviews/interviewActions';
import {
    getExpertsList,
    clearExpertsList,
    getExpert,
    clearExpert,
} from '../../redux/experts/expertActions';

function AddInterview(props) {
    const dispatch = useDispatch();
    const formRef = useRef();
    const expertsList = useSelector((state) => state.experts.expertsList);
    const expert = useSelector((state) => state.experts.expert);
    const interviewCreating = useSelector((state) => state.interviews.interviewCreating);
    const experts = expertsList ? expertsList : [];

    useEffect(() => {
        dispatch(getExpertsList());

        return () => {
            dispatch(clearExpert());
            dispatch(clearExpertsList());
        };
    }, [dispatch]);

    useHotkeys('esc', (e) => {
        e.preventDefault();
        props.history.push('/interviews');
    });

    useHotkeys('ctrl+s', (e) => {
        e.preventDefault();
        formRef.current.values.redirect_to = 'interviews';
        formRef.current.submitForm();
    });

    useHotkeys('ctrl+d', (e) => {
        e.preventDefault();
        formRef.current.values.redirect_to = 'interview';
        formRef.current.submitForm();
    });

    const initialValues = {
        primary_tickers: '',
        expert: null,
        headline: '',
        qualification_questions: '',
        document_file: '',
        date: new Date(),
        rating: '',
    };

    const validationSchema = Yup.object({
        primary_tickers: Yup.string().required('This field is required'),
        expert: Yup.object().required('This field is required').nullable(),
        headline: Yup.string().required('This field is required'),
        qualification_questions: Yup.string().required('This field is required'),
        document_file: Yup.mixed()
            .required('This field is required')
            .test(
                'fileFormat',
                'Unsupported file format. Please upload .doc or .docx file',
                (value) => {
                    return (
                        value &&
                        [
                            'application/msword',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        ].includes(value.type)
                    );
                },
            ),
        date: Yup.date()
            .typeError('Invalid date. Please use this format: yyyy-dd-mm')
            .required('This field is required'),
        rating: Yup.number()
            .max(10, 'Maximum value is 10')
            .typeError('Please enter a valid number')
            .integer('Please enter a valid number')
            .positive('Please enter a positive number')
            .required('This field is required'),
    });

    const onSubmit = async (values, { setErrors }) => {
        const redirectPath = values.redirect_to;
        const formData = new FormData();

        Object.keys(values).forEach((key) => {
            if (key !== 'redirect_to') {
                if (key === 'expert') {
                    formData.append(key, +values[key].id);
                } else if (key === 'date') {
                    let formattedDate = format(values.date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
                    formData.append(key, formattedDate);
                } else {
                    formData.append(key, values[key]);
                }
            }
        });

        const res = await dispatch(createInterview(formData, props.history, redirectPath));

        if (res && res.status === 500 && res.data && res.data.indexOf('too long') > -1) {
            let message = 'File is too long';
            setErrors({ file_size: message });
        }
    };

    const onExpertSelected = (expert) => {
        if (expert) {
            dispatch(getExpert(expert.id));
        } else {
            dispatch(clearExpert());
        }
    };

    return (
        <>
            <Container maxWidth="md" className="mt-2">
                <Breadcrumbs separator="›" className="mb-3">
                    <LinkElement component={Link} to="/interviews" color="primary">
                        Interviews
                    </LinkElement>
                    <Typography color="textPrimary">Add Interview</Typography>
                </Breadcrumbs>

                <Card className="px-8 pt-6 pb-8">
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik) => {
                            return (
                                <Form className="mt-6">
                                    <div className="mb-6">
                                        <FormElement
                                            control="input"
                                            label="Primary Companies"
                                            name="primary_tickers"
                                            size="small"
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <Box display="flex" alignItems="flex-start">
                                            <Box flexGrow="1" mr={1}>
                                                <FormElement
                                                    control="select"
                                                    label="Expert Linked"
                                                    name="expert"
                                                    size="small"
                                                    options={experts}
                                                    labelName="full_name"
                                                    noOptionsText={'No Expert Found'}
                                                    onChangeEvent={onExpertSelected}
                                                />
                                            </Box>

                                            <IconButton
                                                component={Link}
                                                to="/experts/add"
                                                color="primary"
                                                className="p-1"
                                            >
                                                <Tooltip title="Add Expert" placement="top">
                                                    <AddBox style={{ fontSize: 30 }} />
                                                </Tooltip>
                                            </IconButton>
                                        </Box>
                                        {expert && (
                                            <Box fontStyle="italic" mt={2} fontSize={13}>
                                                <Box fontWeight="fontWeightBold">Expert Bio:</Box>
                                                <Truncate lines={3}>{expert.bio}</Truncate>
                                            </Box>
                                        )}
                                    </div>

                                    <div className="mb-6">
                                        <FormElement
                                            control="input"
                                            label="Interview Headline"
                                            name="headline"
                                            size="small"
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <FormElement
                                            control="textarea"
                                            label="Qualification Questions"
                                            name="qualification_questions"
                                            rows={6}
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <FormElement
                                            accept=".doc, .docx"
                                            control="fileupload"
                                            label="Upload Interview"
                                            name="document_file"
                                        />
                                    </div>

                                    {formik.errors.file_size && (
                                        <p className="form-error">{formik.errors.file_size}</p>
                                    )}

                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <div className="mb-6">
                                                <FormElement
                                                    control="datepicker"
                                                    label="Interview Date"
                                                    name="date"
                                                    size="small"
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="mb-6">
                                                <FormElement
                                                    control="input"
                                                    label="Interview Rating (1-10)"
                                                    name="rating"
                                                    size="small"
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Box className="text-center mt-3" textAlign="center">
                                        <Tooltip title="esc" placement="top">
                                            <Button
                                                component={Link}
                                                to="/interviews"
                                                variant="outlined"
                                                color="primary"
                                                className="text-capitalize mx-2 width-md"
                                            >
                                                Cancel
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="ctr + s" placement="top">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                className="text-capitalize mx-2 width-md"
                                                formNoValidate
                                                onClick={() =>
                                                    formik.setFieldValue(
                                                        'redirect_to',
                                                        'interviews',
                                                    )
                                                }
                                                // disabled={!formik.isValid || formik.isSubmitting}
                                            >
                                                Save
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="ctr + d" placement="top">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                className="text-capitalize mx-2 width-md"
                                                formNoValidate
                                                onClick={() =>
                                                    formik.setFieldValue('redirect_to', 'interview')
                                                }
                                                // disabled={!formik.isValid || formik.isSubmitting}
                                            >
                                                Save &#38; View
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </Form>
                            );
                        }}
                    </Formik>
                </Card>
            </Container>

            {interviewCreating && <Loader />}
        </>
    );
}

export default AddInterview;
