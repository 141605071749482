import { GENERAL_CONFIG } from '../../config';
import {
    SET_ANALYSES,
    SET_ANALYSES_ERROR,
    CLEAR_ANALYSES,
    SET_ANALYSIS,
    CLEAR_ANALYSIS,
    SET_ANALYSIS_ERROR,
    SET_ANALYSIS_CREATING,
} from '../actionTypes';

const initialState = {
    analyses: null,
    pagesCount: 0,
    activePage: 1,
    searchValue: '',
    loading: true,
    error: '',
    analysis: null,
    analysisLoading: true,
    analysisError: '',
    analysisCreating: false,
};

const analysisReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ANALYSES:
            const pagesCount = Math.ceil(action.analysesCount / GENERAL_CONFIG.pagination);
            return {
                ...state,
                analyses: action.analyses,
                pagesCount: pagesCount,
                activePage: action.page,
                searchValue: action.searchValue,
                loading: false,
                error: '',
            };
        case SET_ANALYSES_ERROR:
            return {
                ...state,
                analyses: null,
                pagesCount: 0,
                activePage: 1,
                searchValue: '',
                loading: false,
                error: action.error,
            };
        case CLEAR_ANALYSES:
            return {
                ...state,
                analyses: null,
                pagesCount: 0,
                activePage: 1,
                searchValue: '',
                loading: true,
                error: '',
            };
        case SET_ANALYSIS:
            return {
                ...state,
                analysis: action.analysis,
                analysisLoading: false,
                analysisError: '',
            };
        case SET_ANALYSIS_ERROR:
            return {
                ...state,
                analysis: null,
                analysisLoading: false,
                analysisError: action.error,
            };
        case SET_ANALYSIS_CREATING:
            return {
                ...state,
                analysisCreating: action.value,
            };
        case CLEAR_ANALYSIS:
            return {
                ...state,
                analysis: null,
                analysisLoading: true,
                analysisError: '',
            };
        default:
            return state;
    }
};

export default analysisReducer;
