import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { logout, getCurrentUser } from '../../redux/auth/authActions';

function Auth() {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleAuth = useCallback(() => {
        const token = sessionStorage.getItem('token');

        if (token) {
            let isTokenValid = validateToken(token);
            if (isTokenValid) {
                sessionStorage.setItem('token', token);
                axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
                let currentUserId = sessionStorage.getItem('user_id');
                dispatch(getCurrentUser(currentUserId));
            } else {
                dispatch(logout(history));
            }
        } else {
            dispatch(logout(history));
        }

        // Set axios interceptors
        // Add a request interceptor
        axios.interceptors.request.use(
            function (config) {
                // Do something before request is sent
                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            },
        );

        // Add a response interceptor
        axios.interceptors.response.use(
            function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            function (error) {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                if (error.response && error.response.status === 401) {
                    dispatch(logout(history));
                }
                return Promise.reject(error);
            },
        );
    }, [dispatch, history]);

    useEffect(() => {
        handleAuth();
    }, [handleAuth]);

    const validateToken = (token) => {
        if (!token) {
            return false;
        } else {
            const decoded = jwt_decode(token);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                console.warn('access token expired');
                return false;
            } else {
                return true;
            }
        }
    };

    return null;
}

export default Auth;
