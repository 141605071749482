import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import {
    SET_CURRENT_USER,
    LOGOUT,
    FORGOT_PASSWORD_SENT,
    RESET_PASSWORD_SENT,
    ACTIVATE_ENABLED,
} from '../actionTypes';
import { API_CONFIG } from '../../config';

// Login user
export const login = (email, password, history) => {
    return (dispatch) => {
        return axios
            .post(API_CONFIG.login, {
                email,
                password,
            })
            .then((response) => {
                let currentUser = response.data;

                if (currentUser) {
                    let token = currentUser.auth_token;
                    sessionStorage.setItem('token', token);
                    sessionStorage.setItem('user_id', currentUser.pk);
                    sessionStorage.setItem('user_role', currentUser.role);
                    axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
                    dispatch(getCurrentUser(currentUser.pk));
                    // dispatch auth/login success ??
                } else {
                    dispatch(logout(history));
                }
            })
            .catch((error) => {
                return error.response;
            });
    };
};

// Activate account
export const activateAccount = (activation_token) => {
    return (dispatch) => {
        return axios
            .post(API_CONFIG.activateAccount, { activation_token })
            .then((response) => {
                dispatch(setActivateEnabled(true));
            })
            .catch((error) => {
                return error.response;
            });
    };
};

// Logout user
export const logout = (history) => {
    return (dispatch) => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user_id');
        sessionStorage.removeItem('user_role');
        delete axios.defaults.headers.common['Authorization'];
        // history.push('/');
        dispatch({ type: LOGOUT });
    };
};

// Get current user
export const getCurrentUser = (id) => {
    return (dispatch) => {
        axios
            .get(`${API_CONFIG.users}${id}/`)
            .then((response) => {
                let user = response.data;
                dispatch(setCurrentUser(user));
            })
            .catch((error) => {
                console.error(error);
                toastr.error('', 'Something went wrong.');
            });
    };
};

// Set user object data
export const setCurrentUser = (user) => {
    return (dispatch) => {
        dispatch({ type: SET_CURRENT_USER, user });
    };
};

// Forgot Password
export const forgotPassword = (email) => {
    return (dispatch) => {
        return axios
            .post(API_CONFIG.forgotPassword, { email })
            .then((response) => {
                if (response && response.status === 200) {
                    dispatch(setForgotPasswordSent(true));
                }
            })
            .catch((error) => {
                return error.response;
            });
    };
};

export const setForgotPasswordSent = (value) => {
    return (dispatch) => {
        dispatch({ type: FORGOT_PASSWORD_SENT, value });
    };
};

// Reset Password
export const resetPassword = (activation_token, password) => {
    return (dispatch) => {
        return axios
            .post(API_CONFIG.resetPassword, { activation_token, password })
            .then((response) => {
                if (response && response.status === 200) {
                    dispatch(setResetPasswordSent(true));
                }
            })
            .catch((error) => {
                return error.response;
            });
    };
};

export const setResetPasswordSent = (value) => {
    return (dispatch) => {
        dispatch({ type: RESET_PASSWORD_SENT, value });
    };
};

// Change Password
export const changePassword = (old_password, password) => {
    return (dispatch) => {
        return axios
            .post(API_CONFIG.changePassword, { old_password, password })
            .then((response) => {
                if (response && response.status === 200) {
                    dispatch(setActivateEnabled(false));
                }
            })
            .catch((error) => {
                return error.response;
            });
    };
};

export const setActivateEnabled = (value) => {
    localStorage.setItem('activate_enabled', value);
    return (dispatch) => {
        dispatch({ type: ACTIVATE_ENABLED, value });
    };
};
