import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { TextField } from '@material-ui/core';

function Textarea(props) {
    const { name, ...rest } = props;

    return (
        <div className="form-element">
            <Field name={name}>
                {({ field, form }) => {
                    return (
                        <TextField
                            variant="outlined"
                            autoComplete="off"
                            className="form-textarea"
                            fullWidth
                            multiline
                            name={name}
                            value={form.values[name]}
                            error={form.touched[name] && Boolean(form.errors[name])}
                            helperText={form.touched[name] && form.errors[name]}
                            onChange={(e) => form.setFieldValue(name, e.target.value)}
                            onBlur={() => form.setFieldTouched(name, true)}
                            {...rest}
                        />
                    );
                }}
            </Field>
        </div>
    );
}

Textarea.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    rows: PropTypes.number,
};

export default Textarea;
