import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, Typography, Button, Box, Grid } from '@material-ui/core';

function Home() {
    return (
        <div className="home-container py-10">
            <Container maxWidth="md">
                <Box mb={5} display="flex" alignItems="center" justifyContent="center">
                    <div className="mr-10">
                        <div className="logo">
                            <img src="/images/logo.svg" alt="Tegus" />
                        </div>
                        <Box
                            fontWeight={500}
                            fontSize={20}
                            ml={8}
                            lineHeight={1}
                            component="p"
                            className="color-white"
                        >
                            TURBO
                        </Box>
                    </div>
                    <Button
                        className="white width-md"
                        component={Link}
                        to="/sign-in"
                        variant="contained"
                        size="large"
                    >
                        Sign In
                    </Button>
                </Box>

                <Card>
                    <div className="home-content">
                        <Typography variant="h5" component="h2" align="center">
                            <Box fontWeight={600} component="span">
                                The investment community’s leading primary research platform
                            </Box>
                        </Typography>
                        <Grid container spacing={1} justify="space-around" className="mt-5">
                            <Grid item xs={5}>
                                <Typography variant="h6" component="h6">
                                    Consume
                                </Typography>
                                <ul className="bulleted-list mt-2">
                                    <li>Expert transcript database (20,000+ transcripts)</li>
                                    <li>Detailed corporate reports (100+ reports per week)</li>
                                    <li>Hosted conference calls (20+ calls per day)</li>
                                    <li>Demo’s, company meetings (50+ webcasts per week)</li>
                                    <li>Conferences (30+ per year)</li>
                                    <li>Professional connections (10,000+ members)</li>
                                </ul>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="h6" component="h6">
                                    Create
                                </Typography>
                                <ul className="bulleted-list mt-2">
                                    <li>
                                        Hourly expert calls at cost (compare our average ~$300/hr to
                                        competitors at $1,200/hr)
                                    </li>
                                    <li>
                                        High quality phone-based surveys (at 80% less than
                                        competitors)
                                    </li>
                                    <li>Custom research reports (at 90% less)</li>
                                </ul>
                            </Grid>
                        </Grid>
                    </div>
                </Card>

                <div className="text-center py-8">
                    <Box
                        fontWeight={500}
                        fontStyle="italic"
                        className="color-white-80 text-right"
                        display="inline-block"
                    >
                        <Typography variant="h5" component="p">
                            “Tegus is by far our best research resource”
                        </Typography>
                        <Typography variant="h6" component="p">
                            – John Smith
                        </Typography>
                    </Box>
                </div>

                <Box
                    className="partners pt-4"
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="center"
                >
                    <div className="partner-img">
                        <img
                            src="https://www.tegus.co/hubfs/Tegus_July2019_Theme/Images/03-hudsonhill-logo.png"
                            alt="Hudson Hill Capital"
                        />
                    </div>
                    <div className="partner-img">
                        <img
                            src="https://www.tegus.co/hubfs/northernright-logo-white.png"
                            alt="Northern Right Capital"
                        />
                    </div>
                    <div className="partner-img">
                        <img
                            src="https://www.tegus.co/hubfs/oberndorg-logo-white.png"
                            alt="Oberndorf Enterprises"
                        />
                    </div>
                    <div className="partner-img">
                        <img
                            src="https://www.tegus.co/hubfs/thrive-logo-tegus.co.png"
                            alt="Thrive Capital"
                            className="mb-1"
                            style={{ height: '50px' }}
                        />
                    </div>
                    <div className="partner-img">
                        <img
                            src="https://www.tegus.co/hubfs/3L-logo-white-1.png"
                            alt="3L"
                            className="mb-1 ml-2"
                            style={{ height: '40px' }}
                        />
                    </div>
                </Box>
            </Container>
        </div>
    );
}

export default Home;
