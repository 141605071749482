import { GENERAL_CONFIG } from '../../config';
import {
    SET_USERS,
    SET_USERS_ERROR,
    CLEAR_USERS,
    SET_USER,
    CLEAR_USER,
    SET_USER_ERROR,
} from '../actionTypes';

const initialState = {
    users: null,
    pagesCount: 0,
    activePage: 1,
    searchValue: '',
    loading: true,
    error: '',
    user: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERS:
            const usersList = action.users;
            const pagesCount = Math.ceil(action.usersCount / GENERAL_CONFIG.pagination);
            const users = usersList.filter((user) => user.role !== 'admin');
            return {
                ...state,
                users: users,
                pagesCount: pagesCount,
                activePage: action.page,
                searchValue: action.searchValue,
                loading: false,
                error: '',
            };
        case SET_USERS_ERROR:
            return {
                ...state,
                users: null,
                pagesCount: 0,
                activePage: 1,
                searchValue: '',
                loading: false,
                error: action.error,
            };
        case CLEAR_USERS:
            return {
                ...state,
                users: null,
                pagesCount: 0,
                activePage: 1,
                searchValue: '',
                loading: true,
                error: '',
            };
        case SET_USER:
            return {
                ...state,
                user: action.user,
            };
        case SET_USER_ERROR:
            return {
                ...state,
                user: null,
                userError: action.error,
            };
        case CLEAR_USER:
            return {
                ...state,
                user: null,
                userError: '',
            };
        default:
            return state;
    }
};

export default userReducer;
