import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import DialogComponent from '../shared/DialogComponent';

function DeleteExpertDialog(props) {
    const { open, close, removeExpert, expert } = props;
    return (
        <DialogComponent title="Delete Expert" size="sm" open={open} onClose={close}>
            <DialogContent>
                <p className="font-18">
                    {expert && expert.has_interview ? (
                        <>
                            There is an interview linked with this expert. Are you sure you want to
                            delete the expert?
                        </>
                    ) : (
                        <> Are you sure you want to delete the expert?</>
                    )}
                </p>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={close}
                    variant="outlined"
                    color="primary"
                    className="text-capitalize mr-2 width-md"
                >
                    No
                </Button>
                <Button
                    onClick={removeExpert}
                    variant="contained"
                    color="primary"
                    className="text-capitalize width-md"
                >
                    Yes
                </Button>
            </DialogActions>
        </DialogComponent>
    );
}

DeleteExpertDialog.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    removeExpert: PropTypes.func,
    expert: PropTypes.object,
};

export default DeleteExpertDialog;
