import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';

function Datepicker(props) {
    const { name, ...rest } = props;

    return (
        <div className="form-element">
            <Field name={name}>
                {({ field, form }) => {
                    return (
                        <KeyboardDatePicker
                            variant="inline"
                            inputVariant="outlined"
                            format="yyyy-dd-MM"
                            fullWidth
                            autoOk
                            InputAdornmentProps={{ position: 'end' }}
                            name={name}
                            value={form.values[name]}
                            error={form.touched[name] && Boolean(form.errors[name])}
                            helperText={form.touched[name] && form.errors[name]}
                            onChange={(date) => form.setFieldValue(name, date)}
                            {...rest}
                            onBlur={() => form.setFieldTouched(name, true)}
                        />
                    );
                }}
            </Field>
        </div>
    );
}

Datepicker.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.string,
};

export default Datepicker;
