import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

function NoData(props) {
    const { icon, text, iconSize, opcaity } = props;

    return (
        <Box align="center" p={4} className={`opacity-${opcaity}`}>
            <Box fontSize={iconSize} lineHeight={0.8}>
                {icon}
            </Box>
            <Typography variant="h6" component="p">
                {text}
            </Typography>
        </Box>
    );
}

NoData.propTypes = {
    icon: PropTypes.element,
    text: PropTypes.string,
    iconSize: PropTypes.number,
    opcaity: PropTypes.number,
};

export default NoData;
