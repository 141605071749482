import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { API_CONFIG } from '../../config';
import {
    SET_EXPERTS,
    SET_EXPERTS_ERROR,
    CLEAR_EXPERTS,
    SET_EXPERT,
    CLEAR_EXPERT,
    SET_EXPERT_ERROR,
    SET_EXPERTS_LIST,
    CLEAR_EXPERTS_LIST,
} from '../actionTypes';

// Get all experts
export const getExperts = (page, searchValue, showDeleted) => {
    return (dispatch) => {
        axios
            .get(API_CONFIG.experts, {
                params: {
                    page: page,
                    search: searchValue,
                    is_deleted: showDeleted,
                },
            })
            .then((response) => {
                const experts = response.data.results;
                const expertsCount = response.data.count;
                dispatch(setExperts(experts, expertsCount, page, searchValue));
            })
            .catch((error) => {
                dispatch(setExpertsError(error));
            });
    };
};

// Set experts
export const setExperts = (experts, expertsCount, page, searchValue) => {
    return { type: SET_EXPERTS, experts, expertsCount, page, searchValue };
};

export const setExpertsError = (error) => {
    return { type: SET_EXPERTS_ERROR, error };
};

export const clearExperts = () => {
    return { type: CLEAR_EXPERTS };
};

// Create an expert
export const createExpert = (data, history, redirectPath) => {
    return (dispatch) => {
        return axios
            .post(API_CONFIG.experts, {
                ...data,
            })
            .then((response) => {
                if (response.status && response.status === 201) {
                    if (redirectPath === 'experts') {
                        history.push('/experts');
                    } else if (redirectPath === 'add-interview') {
                        history.push('/interviews/add');
                    }
                }
            })
            .catch((error) => {
                toastr.error('', 'Something went wrong.');
                return error.response;
            });
    };
};

// Delete an expert
export const deleteExpert = (id) => {
    return (dispatch, getState) => {
        let page = getState().experts.activePage;
        let searchValue = getState().experts.searchValue;
        axios
            .delete(`${API_CONFIG.experts}${id}/`)
            .then((response) => {
                dispatch(getExperts(page, searchValue, false));
            })
            .catch((error) => {
                console.error(error);
                toastr.error('', 'Something went wrong.');
            });
    };
};

// Get expert with id
export const getExpert = (id) => {
    return (dispatch) => {
        axios
            .get(`${API_CONFIG.experts}${id}/`)
            .then((response) => {
                let expert = response.data;
                dispatch(setExpert(expert));
            })
            .catch((error) => {
                dispatch(setExpertError(error));
            });
    };
};

// Set expert object data
export const setExpert = (expert) => {
    return (dispatch) => {
        dispatch({ type: SET_EXPERT, expert });
    };
};

export const setExpertError = (error) => {
    return { type: SET_EXPERT_ERROR, error };
};

// Clear expert object
export const clearExpert = () => {
    return { type: CLEAR_EXPERT };
};

// Edit expert
export const editExpert = (id, data, history) => {
    return (dispatch) => {
        axios
            .patch(`${API_CONFIG.experts}${id}/`, {
                ...data,
            })
            .then((response) => {
                history.push('/experts');
            })
            .catch((error) => {
                console.error(error);
                toastr.error('', 'Something went wrong.');
            });
    };
};

// Get all experts list
export const getExpertsList = () => {
    return (dispatch) => {
        axios
            .get(API_CONFIG.expertsList)
            .then((response) => {
                const expertsList = response.data;
                dispatch(setExpertsList(expertsList));
            })
            .catch((error) => {
                console.error(error);
                toastr.error('', 'Something went wrong.');
            });
    };
};

// Set experts list
export const setExpertsList = (expertsList) => {
    return { type: SET_EXPERTS_LIST, expertsList };
};

// Clear experts list
export const clearExpertsList = () => {
    return { type: CLEAR_EXPERTS_LIST };
};
