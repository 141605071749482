import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

function DialogComponent(props) {
    const { title, children, open, onClose, size } = props;

    return (
        <Dialog open={open} onClose={onClose} maxWidth={size} fullWidth>
            <DialogTitle disableTypography>
                <Typography variant="h5">{title}</Typography>
                <IconButton aria-label="close" className="dialog-close" onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            {children}
        </Dialog>
    );
}

DialogComponent.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    size: PropTypes.string,
};

export default DialogComponent;
