import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import DialogComponent from '../shared/DialogComponent';

function DeleteAnalysisDialog(props) {
    const { open, close, removeAnalysis } = props;
    return (
        <DialogComponent title="Delete Analysis" size="sm" open={open} onClose={close}>
            <DialogContent>
                <p className="font-18">Are you sure you want to delete the analysis?</p>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={close}
                    variant="outlined"
                    color="primary"
                    className="text-capitalize mr-2 width-md"
                >
                    No
                </Button>
                <Button
                    onClick={removeAnalysis}
                    variant="contained"
                    color="primary"
                    className="text-capitalize width-md"
                >
                    Yes
                </Button>
            </DialogActions>
        </DialogComponent>
    );
}

DeleteAnalysisDialog.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    removeAnalysis: PropTypes.func,
};

export default DeleteAnalysisDialog;
