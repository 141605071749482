import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { IconButton, Tooltip } from '@material-ui/core';
import { Backup } from '@material-ui/icons';

function FileUploadSm(props) {
    const { name, color, tooltipText, fileChanged, ...rest } = props;

    return (
        <Field name={name}>
            {({ field, form }) => {
                return (
                    <div className="file-upload-wrap">
                        <div className="file-upload">
                            <label>
                                <input
                                    // "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    type="file"
                                    name={name}
                                    onChange={(event) => {
                                        fileChanged(event.currentTarget.files[0]);
                                        form.setFieldValue(name, event.currentTarget.files[0]);
                                        event.currentTarget.value = '';
                                    }}
                                    onBlur={() => form.setFieldTouched(name, true)}
                                    {...rest}
                                />
                                <Tooltip title={tooltipText ? tooltipText : ''} placement="top">
                                    <IconButton color={color} component="span">
                                        <Backup />
                                    </IconButton>
                                </Tooltip>
                            </label>
                        </div>
                    </div>
                );
            }}
        </Field>
    );
}

FileUploadSm.propTypes = {
    name: PropTypes.string,
};

export default FileUploadSm;
