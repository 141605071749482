import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Container, Card, Typography, Button, Box } from '@material-ui/core';
import FormElement from '../shared/FormElement';
import { login, activateAccount } from '../../redux/auth/authActions';

function Login(props) {
    const dispatch = useDispatch();
    const activation_token = props.match.params.activate_token;

    const initialValues = {
        email: '',
        password: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email format').required('This field is required'),
        password: Yup.string().required('This field is required'),
    });

    const onSubmit = async (values, { setErrors }) => {
        if (activation_token) {
            const result = await dispatch(activateAccount(activation_token));

            if (result && result.status === 400 && result.data && result.data.activation_token) {
                let message =
                    result.data && result.data.activation_token && result.data.activation_token[0];
                setErrors({ activation_token_err: message });
                return false;
            }
        }

        const res = await dispatch(login(values.email, values.password, props.history));

        if (res && res.status === 401 && res.data && res.data.non_field_errors) {
            let message = res.data && res.data.non_field_errors && res.data.non_field_errors[0];
            setErrors({ credential: message });
        }
    };

    return (
        <div className="auth-container">
            <Container maxWidth="sm">
                <div className="text-center mb-4">
                    <Link to="/" className="logo">
                        <img src="/images/logo.svg" alt="Tegus" />
                    </Link>
                </div>

                <Card>
                    <div className="auth-content">
                        <Typography variant="h4" component="h2" align="center">
                            {activation_token ? 'Activate Account' : 'Sign In'}
                        </Typography>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {(formik) => {
                                return (
                                    <Form className="mt-8">
                                        <div className="mb-8">
                                            <FormElement
                                                control="input"
                                                type="email"
                                                label="Email"
                                                name="email"
                                            />
                                        </div>

                                        <div className="mb-10">
                                            <FormElement
                                                control="input"
                                                type="password"
                                                label="Password"
                                                name="password"
                                            />
                                        </div>

                                        {formik.errors.credential && (
                                            <p className="form-error">{formik.errors.credential}</p>
                                        )}

                                        {formik.errors.activation_token_err && (
                                            <p className="form-error">
                                                {formik.errors.activation_token_err}
                                            </p>
                                        )}

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            type="submit"
                                            formNoValidate
                                            // disabled={ !formik.isValid || formik.isSubmitting }
                                        >
                                            {activation_token ? 'Activate' : 'Sign In'}
                                        </Button>

                                        {!activation_token && (
                                            <Box className="text-center mt-3" textAlign="center">
                                                <Button
                                                    component={Link}
                                                    to="/forgot-password"
                                                    color="primary"
                                                    className="text-capitalize"
                                                >
                                                    Forgot your password?
                                                </Button>
                                            </Box>
                                        )}
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </Card>
            </Container>
        </div>
    );
}

export default Login;
