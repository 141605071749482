import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Box } from '@material-ui/core';
import { Rating } from '@material-ui/lab';

const StyledRating = withStyles(({ color }) => ({
    iconFilled: { color: '#1a8ad4' },
}))(Rating);

function StarRating(props) {
    const { val } = props;

    return (
        <Box display="inline-flex" alignItems="center">
            <StyledRating value={val / 2} precision={0.1} readOnly size="small" />
        </Box>
    );
}

StarRating.propTypes = {
    val: PropTypes.number,
};

export default StarRating;
