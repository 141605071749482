import { GENERAL_CONFIG } from '../../config';
import {
    SET_EXPERTS,
    SET_EXPERTS_ERROR,
    CLEAR_EXPERTS,
    SET_EXPERT,
    CLEAR_EXPERT,
    SET_EXPERT_ERROR,
    SET_EXPERTS_LIST,
    CLEAR_EXPERTS_LIST,
} from '../actionTypes';

const initialState = {
    experts: null,
    pagesCount: 0,
    activePage: 1,
    searchValue: '',
    loading: true,
    error: '',
    expert: null,
    expertLoading: true,
    expertError: '',
    expertsList: null,
};

const expertReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXPERTS:
            const pagesCount = Math.ceil(action.expertsCount / GENERAL_CONFIG.pagination);
            return {
                ...state,
                experts: action.experts,
                pagesCount: pagesCount,
                activePage: action.page,
                searchValue: action.searchValue,
                loading: false,
                error: '',
            };
        case SET_EXPERTS_ERROR:
            return {
                ...state,
                experts: null,
                pagesCount: 0,
                activePage: 1,
                searchValue: '',
                loading: false,
                error: action.error,
            };
        case CLEAR_EXPERTS:
            return {
                ...state,
                experts: null,
                pagesCount: 0,
                activePage: 1,
                searchValue: '',
                loading: true,
                error: '',
            };
        case SET_EXPERT:
            return {
                ...state,
                expert: action.expert,
                expertLoading: false,
                expertError: '',
            };
        case SET_EXPERT_ERROR:
            return {
                ...state,
                expert: null,
                expertLoading: false,
                expertError: action.error,
            };
        case CLEAR_EXPERT:
            return {
                ...state,
                expert: null,
                expertLoading: true,
                expertError: '',
            };
        case SET_EXPERTS_LIST:
            let expertsList = action.expertsList.filter((expert) => !expert.is_deleted);
            return {
                ...state,
                expertsList: expertsList,
            };
        case CLEAR_EXPERTS_LIST:
            return {
                ...state,
                expertsList: null,
            };
        default:
            return state;
    }
};

export default expertReducer;
