import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import DialogComponent from '../shared/DialogComponent';

function DeleteUserDialog(props) {
    const { open, close, removeUser } = props;
    return (
        <DialogComponent title="Delete User" size="sm" open={open} onClose={close}>
            <DialogContent>
                <p className="font-18">Are you sure you want to delete the user?</p>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={close}
                    variant="outlined"
                    color="primary"
                    className="text-capitalize mr-2 width-md"
                >
                    No
                </Button>
                <Button
                    onClick={removeUser}
                    variant="contained"
                    color="primary"
                    className="text-capitalize width-md"
                >
                    Yes
                </Button>
            </DialogActions>
        </DialogComponent>
    );
}

DeleteUserDialog.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    removeUser: PropTypes.func,
};

export default DeleteUserDialog;
