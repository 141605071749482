import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Container, Card, Typography, Button, Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import FormElement from '../shared/FormElement';
import { forgotPassword, setForgotPasswordSent } from '../../redux/auth/authActions';

function ForgotPassword() {
    const dispatch = useDispatch();
    const forgotPasswordSent = useSelector((state) => state.auth.forgotPasswordSent);

    useEffect(() => {
        return () => {
            dispatch(setForgotPasswordSent(false));
        };
    }, [dispatch]);

    const initialValues = {
        email: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email format').required('This field is required'),
    });

    const onSubmit = async (values, { setErrors }) => {
        const res = await dispatch(forgotPassword(values.email));

        if (res && res.status === 400 && res.data && res.data.non_field_errors) {
            let message = res.data && res.data.non_field_errors && res.data.non_field_errors[0];
            setErrors({ wrong_email: message });
        }
    };

    return (
        <div className="auth-container">
            <Container maxWidth="sm">
                <div className="text-center mb-4">
                    <Link to="/" className="logo">
                        <img src="/images/logo.svg" alt="Tegus" />
                    </Link>
                </div>

                <Card>
                    <div className="auth-content">
                        <Typography variant="h4" component="h2" align="center">
                            Forgot Password
                        </Typography>
                        {forgotPasswordSent ? (
                            <Box pt={8} pb={4}>
                                <Alert severity="success" variant="outlined">
                                    <AlertTitle>Success</AlertTitle>A Reset Password link was sent
                                    to your email address. <br /> Please check your email to reset
                                    the password.
                                </Alert>

                                <Box className="text-center mt-3" textAlign="center">
                                    <Button
                                        component={Link}
                                        to="/sign-in"
                                        color="primary"
                                        className="text-capitalize"
                                    >
                                        Back to Sign In
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {(formik) => {
                                    return (
                                        <Form className="mt-8">
                                            <div className="mb-8">
                                                <FormElement
                                                    control="input"
                                                    type="email"
                                                    label="Email"
                                                    name="email"
                                                />
                                            </div>

                                            {formik.errors.wrong_email && (
                                                <p className="form-error">
                                                    {formik.errors.wrong_email}
                                                </p>
                                            )}

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                fullWidth
                                                type="submit"
                                                formNoValidate
                                                // disabled={!formik.isValid || formik.isSubmitting}
                                            >
                                                Submit
                                            </Button>

                                            <Box className="text-center mt-3" textAlign="center">
                                                <Button
                                                    component={Link}
                                                    to="/sign-in"
                                                    color="primary"
                                                    className="text-capitalize"
                                                >
                                                    Sign In
                                                </Button>
                                            </Box>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        )}
                    </div>
                </Card>
            </Container>
        </div>
    );
}

export default ForgotPassword;
