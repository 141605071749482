import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    IconButton,
    Button,
    TextField,
    InputAdornment,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import { Edit, Delete, Add, Search, PermContactCalendar } from '@material-ui/icons';
import { Pagination, Alert } from '@material-ui/lab';
import { format, parseISO } from 'date-fns';
import NoData from '../shared/NoData';
import StarRating from '../shared/StarRating';
import DeleteExpertDialog from './DeleteExpertDialog';
import { getExperts, deleteExpert } from '../../redux/experts/expertActions';

let timer = 0;
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 1000,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

function Experts() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const [deleteExpertDialogOpen, setDeleteExpertDialogOpen] = useState(false);
    const [currentExpert, setCurrentExpert] = useState(null);
    const [page, setPage] = useState(1);
    const experts = useSelector((state) => state.experts.experts);
    const error = useSelector((state) => state.experts.error);
    const loading = useSelector((state) => state.experts.loading);
    const pagesCount = useSelector((state) => state.experts.pagesCount);
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    useEffect(() => {
        dispatch(getExperts(1, '', false));
    }, [dispatch]);

    const openDeleteExpertDialog = (expert) => {
        setDeleteExpertDialogOpen(true);
        setCurrentExpert(expert);
    };

    const closeDeleteExpertDialog = () => {
        setDeleteExpertDialogOpen(false);
        setCurrentExpert(null);
    };

    const removeExpert = () => {
        setDeleteExpertDialogOpen(false);
        dispatch(deleteExpert(currentExpert.id));
        setCurrentExpert(null);
    };

    const onPageChange = (e, activePage) => {
        if (page !== activePage) {
            setPage(activePage);
            dispatch(getExperts(activePage, searchValue, false));
        }
    };

    const searchExperts = (e) => {
        let value = e.target.value;
        const delay = 300;
        setSearchValue(value);
        clearTimeout(timer);
        timer = setTimeout(() => {
            setPage(1);
            dispatch(getExperts(1, value, false));
        }, delay);
    };

    return (
        <Container className="mt-6">
            <Grid
                container
                className="mb-4"
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item xs={5}>
                    <TextField
                        label="Search"
                        type="search"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={searchValue}
                        onChange={searchExperts}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search color="disabled" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                {isAdmin && (
                    <Grid item xs={3} className="text-right">
                        <Button
                            component={Link}
                            to="/experts/add"
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                        >
                            Add Expert
                        </Button>
                    </Grid>
                )}
            </Grid>

            {loading ? (
                <Box display="flex" justifyContent="center" p={4}>
                    <CircularProgress size={50} />
                </Box>
            ) : error ? (
                <Alert severity="error" className="bordered">
                    Something went wrong!
                </Alert>
            ) : (
                experts && (
                    <div className={classes.root}>
                        <Paper className={classes.paper}>
                            <TableContainer>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="12%" className="text-nowrap">
                                                Expert
                                            </TableCell>
                                            <TableCell width="15%">Primary Companies</TableCell>
                                            <TableCell width="15%">Secondary Companies</TableCell>
                                            <TableCell width="30%" className="text-nowrap">
                                                Timeline
                                            </TableCell>
                                            <TableCell width="120" className="text-nowrap">
                                                Rating
                                            </TableCell>
                                            <TableCell width="110" className="text-nowrap">
                                                Date Added
                                            </TableCell>
                                            {isAdmin && (
                                                <TableCell className="text-nowrap">
                                                    Actions
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {experts.length > 0 ? (
                                            experts.map((expert) => (
                                                <TableRow hover key={expert.id}>
                                                    <TableCell>
                                                        <Link
                                                            to={`/experts/${expert.id}`}
                                                            className="link primary"
                                                        >
                                                            {expert.name} {expert.surname}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        {expert.primary_companies}
                                                    </TableCell>
                                                    <TableCell>
                                                        {expert.secondary_companies}
                                                    </TableCell>
                                                    <TableCell>
                                                        {expert.timeline[0]}
                                                        {expert.timeline.length > 1 && '...'}
                                                    </TableCell>
                                                    <TableCell>
                                                        <StarRating val={expert.rating} />
                                                    </TableCell>
                                                    <TableCell>
                                                        {format(
                                                            parseISO(expert.created),
                                                            'yyyy-dd-MM',
                                                        )}
                                                    </TableCell>
                                                    {isAdmin && (
                                                        <TableCell>
                                                            <Box display="flex">
                                                                <IconButton
                                                                    component={Link}
                                                                    to={`/experts/edit/${expert.id}`}
                                                                    size="small"
                                                                    className="mr-2"
                                                                >
                                                                    <Edit
                                                                        color="primary"
                                                                        fontSize="small"
                                                                    />
                                                                </IconButton>
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() =>
                                                                        openDeleteExpertDialog(
                                                                            expert,
                                                                        )
                                                                    }
                                                                >
                                                                    <Delete
                                                                        color="error"
                                                                        fontSize="small"
                                                                    />
                                                                </IconButton>
                                                            </Box>
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={isAdmin ? 8 : 7}>
                                                    <NoData
                                                        text="No Experts Found"
                                                        iconSize={60}
                                                        opcaity={30}
                                                        icon={
                                                            <PermContactCalendar fontSize="inherit" />
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        {experts && experts.length > 0 && (
                            <Box display="flex" justifyContent="center" mt={3}>
                                <Pagination
                                    count={pagesCount}
                                    color="primary"
                                    shape="rounded"
                                    boundaryCount={2}
                                    page={page}
                                    onChange={onPageChange}
                                />
                            </Box>
                        )}
                    </div>
                )
            )}

            <DeleteExpertDialog
                open={deleteExpertDialogOpen}
                close={closeDeleteExpertDialog}
                removeExpert={removeExpert}
                expert={currentExpert}
            />
        </Container>
    );
}

export default Experts;
