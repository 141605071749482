import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Card,
    Button,
    Box,
    Breadcrumbs,
    Typography,
    Link as LinkElement,
    Tooltip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import FormElement from '../shared/FormElement';
import { getUser, clearUser, editUser } from '../../redux/users/userActions';

function EditUser(props) {
    const dispatch = useDispatch();
    const formRef = useRef();
    const user = useSelector((state) => state.users.user);
    const userError = useSelector((state) => state.users.userError);
    const history = props.history;
    const id = props.match.params.id;

    useEffect(() => {
        dispatch(getUser(id));

        return () => {
            dispatch(clearUser());
        };
    }, [dispatch, id]);

    useHotkeys('esc', (e) => {
        e.preventDefault();
        props.history.push('/users');
    });

    useHotkeys('ctrl+s', (e) => {
        e.preventDefault();
        formRef.current.submitForm();
    });

    const initialValues = {
        company_name: ' ',
        first_name: ' ',
        last_name: ' ',
    };

    const userValues = user
        ? {
              company_name: user.company_name,
              first_name: user.first_name,
              last_name: user.last_name,
          }
        : null;

    const defaultValues = userValues ? userValues : initialValues;

    const validationSchema = Yup.object({
        company_name: Yup.string().required('This field is required'),
        first_name: Yup.string().required('This field is required'),
        last_name: Yup.string().required('This field is required'),
    });

    const onSubmit = (values) => {
        let { company_name, first_name, last_name } = values;
        let data = { company_name, first_name, last_name };
        dispatch(editUser(id, data, history));
    };

    return (
        <Container maxWidth="sm" className="mt-2">
            <Breadcrumbs separator="›" className="mb-3">
                <LinkElement component={Link} to="/users" color="primary">
                    Users
                </LinkElement>
                <Typography color="textPrimary">Edit User</Typography>
            </Breadcrumbs>

            <Card className="px-8 pt-6 pb-8">
                {userError ? (
                    <Alert severity="error" className="bordered">
                        Something went wrong!
                    </Alert>
                ) : (
                    <Formik
                        innerRef={formRef}
                        initialValues={defaultValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                    >
                        {(formik) => {
                            return (
                                <Form className="mt-6">
                                    <div className="mb-6">
                                        <FormElement
                                            control="input"
                                            label="Company Name"
                                            name="company_name"
                                            size="small"
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <FormElement
                                            control="input"
                                            label="Name"
                                            name="first_name"
                                            size="small"
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <FormElement
                                            control="input"
                                            label="Surname"
                                            name="last_name"
                                            size="small"
                                        />
                                    </div>

                                    <Box className="text-center mt-3" textAlign="center">
                                        <Tooltip title="esc" placement="top">
                                            <Button
                                                component={Link}
                                                to="/users"
                                                variant="outlined"
                                                color="primary"
                                                className="text-capitalize mx-2 width-md"
                                            >
                                                Cancel
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="ctr + s" placement="top">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                className="text-capitalize mx-2 width-md"
                                                formNoValidate
                                                // disabled={!formik.isValid || formik.isSubmitting}
                                            >
                                                Save
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Card>
        </Container>
    );
}

export default EditUser;
