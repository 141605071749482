import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Button, IconButton } from '@material-ui/core';
import { Backup, Delete } from '@material-ui/icons';

function FileUpload(props) {
    const { name, label, fileChanged, ...rest } = props;

    return (
        <div className="form-element">
            <Field name={name}>
                {({ field, form }) => {
                    return (
                        <div className="file-upload-wrap">
                            <div className="file-upload">
                                <label>
                                    <input
                                        // "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        type="file"
                                        name={name}
                                        onChange={(event) => {
                                            if (fileChanged) {
                                                fileChanged(event.currentTarget.files[0]);
                                            }
                                            form.setFieldValue(name, event.currentTarget.files[0]);
                                            event.currentTarget.value = '';
                                        }}
                                        onBlur={() => form.setFieldTouched(name, true)}
                                        {...rest}
                                    />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        component="span"
                                        startIcon={<Backup className="mr-1" />}
                                        className="text-capitalize width-lg"
                                    >
                                        {label}
                                    </Button>
                                </label>

                                {form.values[name] && (
                                    <div className="file-name">
                                        <p className="name">{form.values[name].name}</p>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                form.setFieldValue(name, '');
                                            }}
                                        >
                                            <Delete color="error" />
                                        </IconButton>
                                    </div>
                                )}
                            </div>

                            {form.errors[name] && form.touched[name] && (
                                <p className="file-error">{form.errors[name]}</p>
                            )}
                        </div>
                    );
                }}
            </Field>
        </div>
    );
}

FileUpload.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
};

export default FileUpload;
