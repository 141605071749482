import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    IconButton,
    Button,
    // TextField,
    // InputAdornment,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import { Edit, Delete, Add, Message } from '@material-ui/icons';
import { Pagination, Alert } from '@material-ui/lab';
import { format, parseISO } from 'date-fns';
import NoData from '../shared/NoData';
import StarRating from '../shared/StarRating';
import DeleteInterviewDialog from './DeleteInterviewDialog';
import { getInterviews, deleteInterview } from '../../redux/interviews/interviewActions';

// let timer = 0;
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 1000,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

function Interviews() {
    const dispatch = useDispatch();
    const classes = useStyles();
    // const [searchValue, setSearchValue] = useState('');
    const [deleteInterviewDialogOpen, setDeleteInterviewDialogOpen] = useState(false);
    const [currentInterview, setCurrentInterview] = useState(null);
    const [page, setPage] = useState(1);
    const interviews = useSelector((state) => state.interviews.interviews);
    const error = useSelector((state) => state.interviews.error);
    const loading = useSelector((state) => state.interviews.loading);
    const pagesCount = useSelector((state) => state.interviews.pagesCount);
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    useEffect(() => {
        dispatch(getInterviews(1, ''));
    }, [dispatch]);

    const openDeleteInterviewDialog = (index) => {
        setDeleteInterviewDialogOpen(true);
        setCurrentInterview(index);
    };

    const closeDeleteInterviewDialog = () => {
        setDeleteInterviewDialogOpen(false);
        setCurrentInterview(null);
    };

    const removeInterview = () => {
        setDeleteInterviewDialogOpen(false);
        dispatch(deleteInterview(currentInterview));
        setCurrentInterview(null);
    };

    const onPageChange = (e, activePage) => {
        if (page !== activePage) {
            setPage(activePage);
            dispatch(getInterviews(activePage, ''));
        }
    };

    // const searchInterviews = (e) => {
    //     let value = e.target.value;
    //     const delay = 300;
    //     setSearchValue(value);
    //     clearTimeout(timer);
    //     timer = setTimeout(() => {
    //         setPage(1);
    //         dispatch(getInterviews(1, value));
    //     }, delay);
    // };

    return (
        <Container className="mt-6">
            <Grid
                container
                className="mb-4"
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item xs={5}>
                    {/* <TextField
                        label="Search"
                        type="search"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={searchValue}
                        onChange={searchInterviews}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search color="disabled" />
                                </InputAdornment>
                            ),
                        }}
                    /> */}
                </Grid>
                {isAdmin && (
                    <Grid item xs={3} className="text-right">
                        <Button
                            component={Link}
                            to="/interviews/add"
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                        >
                            Add Interview
                        </Button>
                    </Grid>
                )}
            </Grid>

            {loading ? (
                <Box display="flex" justifyContent="center" p={4}>
                    <CircularProgress size={50} />
                </Box>
            ) : error ? (
                <Alert severity="error" className="bordered">
                    Something went wrong!
                </Alert>
            ) : (
                interviews && (
                    <div className={classes.root}>
                        <Paper className={classes.paper}>
                            <TableContainer>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="20%">Primary Companies</TableCell>
                                            <TableCell width="20%">Expert Linked</TableCell>
                                            <TableCell width="40%">Headline</TableCell>
                                            <TableCell width="120" className="text-nowrap">
                                                Rating
                                            </TableCell>
                                            <TableCell width="110" className="text-nowrap">
                                                Date Added
                                            </TableCell>
                                            {isAdmin && (
                                                <TableCell className="text-nowrap">
                                                    Actions
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {interviews.length > 0 ? (
                                            interviews.map((interview) => (
                                                <TableRow hover key={interview.id}>
                                                    <TableCell>
                                                        {interview.primary_tickers}
                                                    </TableCell>
                                                    <TableCell>
                                                        {interview.expert.is_deleted ? (
                                                            interview.expert.full_name
                                                        ) : (
                                                            <Link
                                                                to={`/experts/${interview.expert.id}`}
                                                                className="link primary"
                                                            >
                                                                {interview.expert.full_name}
                                                            </Link>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link
                                                            to={`/interviews/${interview.id}`}
                                                            className="link primary"
                                                        >
                                                            {interview.headline}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        <StarRating val={interview.rating} />
                                                    </TableCell>
                                                    <TableCell className="text-nowrap">
                                                        {format(
                                                            parseISO(interview.date),
                                                            'yyyy-dd-MM',
                                                        )}
                                                    </TableCell>
                                                    {isAdmin && (
                                                        <TableCell>
                                                            <Box display="flex">
                                                                <IconButton
                                                                    component={Link}
                                                                    to={`/interviews/edit/${interview.id}`}
                                                                    size="small"
                                                                    className="mr-2"
                                                                >
                                                                    <Edit
                                                                        color="primary"
                                                                        fontSize="small"
                                                                    />
                                                                </IconButton>
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() =>
                                                                        openDeleteInterviewDialog(
                                                                            interview.id,
                                                                        )
                                                                    }
                                                                >
                                                                    <Delete
                                                                        color="error"
                                                                        fontSize="small"
                                                                    />
                                                                </IconButton>
                                                            </Box>
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={isAdmin ? 8 : 7}>
                                                    <NoData
                                                        text="No Interviews Found"
                                                        iconSize={60}
                                                        opcaity={30}
                                                        icon={<Message fontSize="inherit" />}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        {interviews && interviews.length > 0 && (
                            <Box display="flex" justifyContent="center" mt={3}>
                                <Pagination
                                    count={pagesCount}
                                    color="primary"
                                    shape="rounded"
                                    boundaryCount={2}
                                    page={page}
                                    onChange={onPageChange}
                                />
                            </Box>
                        )}
                    </div>
                )
            )}

            <DeleteInterviewDialog
                open={deleteInterviewDialogOpen}
                close={closeDeleteInterviewDialog}
                removeInterview={removeInterview}
            />
        </Container>
    );
}

export default Interviews;
