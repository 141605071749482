import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    IconButton,
    Button,
    TextField,
    InputAdornment,
    Grid,
    CircularProgress,
    Tooltip,
} from '@material-ui/core';
import { Edit, Delete, PictureAsPdf, Add, Search, Assessment } from '@material-ui/icons';
import { Pagination, Alert } from '@material-ui/lab';
import { format, parseISO } from 'date-fns';
import NoData from '../shared/NoData';
import DeleteAnalysisDialog from './DeleteAnalysisDialog';
import {
    getAnalyses,
    deleteAnalysis,
    downloadAnalysis,
} from '../../redux/analyses/analysisActions';

let timer = 0;
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 800,
        tableLayout: 'fixed',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

function Analyses() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const [deleteAnalysisDialogOpen, setDeleteAnalysisDialogOpen] = useState(false);
    const [currentAnalysis, setCurrentAnalysis] = useState(null);
    const [page, setPage] = useState(1);
    const analyses = useSelector((state) => state.analyses.analyses);
    const error = useSelector((state) => state.analyses.error);
    const loading = useSelector((state) => state.analyses.loading);
    const pagesCount = useSelector((state) => state.analyses.pagesCount);
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    useEffect(() => {
        dispatch(getAnalyses(1, ''));
    }, [dispatch]);

    const openDeleteAnalysisDialog = (index) => {
        setDeleteAnalysisDialogOpen(true);
        setCurrentAnalysis(index);
    };

    const closeDeleteAnalysisDialog = () => {
        setDeleteAnalysisDialogOpen(false);
        setCurrentAnalysis(null);
    };

    const removeAnalysis = () => {
        setDeleteAnalysisDialogOpen(false);
        dispatch(deleteAnalysis(currentAnalysis));
        setCurrentAnalysis(null);
    };

    const onPageChange = (e, activePage) => {
        if (page !== activePage) {
            setPage(activePage);
            dispatch(getAnalyses(activePage, searchValue));
        }
    };

    const searchAnalyses = (e) => {
        let value = e.target.value;
        const delay = 300;
        setSearchValue(value);
        clearTimeout(timer);
        timer = setTimeout(() => {
            setPage(1);
            dispatch(getAnalyses(1, value));
        }, delay);
    };

    const downloadFile = (id) => {
        dispatch(downloadAnalysis(id));
    };

    return (
        <Container className="mt-6">
            <Grid
                container
                className="mb-4"
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item xs={5}>
                    <TextField
                        label="Search"
                        type="search"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={searchValue}
                        onChange={searchAnalyses}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search color="disabled" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                {isAdmin && (
                    <Grid item xs={3} className="text-right">
                        <Button
                            component={Link}
                            to="/analysis/add"
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                        >
                            Add Analysis
                        </Button>
                    </Grid>
                )}
            </Grid>

            {loading ? (
                <Box display="flex" justifyContent="center" p={4}>
                    <CircularProgress size={50} />
                </Box>
            ) : error ? (
                <Alert severity="error" className="bordered">
                    Something went wrong!
                </Alert>
            ) : (
                analyses && (
                    <div className={classes.root}>
                        <Paper className={classes.paper}>
                            <TableContainer>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="25%">Company</TableCell>
                                            <TableCell width="50%" className="text-nowrap">
                                                Headline
                                            </TableCell>
                                            <TableCell width="110" className="text-nowrap">
                                                Date Added
                                            </TableCell>
                                            <TableCell
                                                width={isAdmin ? '130' : '90'}
                                                className="text-nowrap"
                                            >
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {analyses.length > 0 ? (
                                            analyses.map((analysis) => (
                                                <TableRow hover key={analysis.id}>
                                                    <TableCell>
                                                        {analysis.company_name
                                                            ? analysis.company_name
                                                            : 'N/A'}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip
                                                            title={analysis.headline}
                                                            placement="top"
                                                        >
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="link primary ellipsis va-middle"
                                                                href={analysis.pdf_file}
                                                            >
                                                                {analysis.headline}
                                                            </a>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell className="text-nowrap">
                                                        {format(
                                                            parseISO(analysis.created),
                                                            'yyyy-dd-MM',
                                                        )}
                                                    </TableCell>

                                                    <TableCell>
                                                        <Box display="flex">
                                                            {isAdmin && (
                                                                <IconButton
                                                                    component={Link}
                                                                    to={`/analysis/edit/${analysis.id}`}
                                                                    size="small"
                                                                    className="mr-2"
                                                                >
                                                                    <Edit
                                                                        color="primary"
                                                                        fontSize="small"
                                                                    />
                                                                </IconButton>
                                                            )}
                                                            <IconButton
                                                                size="small"
                                                                className="mr-2"
                                                                onClick={() =>
                                                                    downloadFile(analysis.id)
                                                                }
                                                            >
                                                                <PictureAsPdf
                                                                    color="secondary"
                                                                    fontSize="small"
                                                                />
                                                            </IconButton>
                                                            {isAdmin && (
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() =>
                                                                        openDeleteAnalysisDialog(
                                                                            analysis.id,
                                                                        )
                                                                    }
                                                                >
                                                                    <Delete
                                                                        color="error"
                                                                        fontSize="small"
                                                                    />
                                                                </IconButton>
                                                            )}
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={isAdmin ? 4 : 3}>
                                                    <NoData
                                                        text="No Analysis Found"
                                                        iconSize={60}
                                                        opcaity={30}
                                                        icon={<Assessment fontSize="inherit" />}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        {analyses && analyses.length > 0 && (
                            <Box display="flex" justifyContent="center" mt={3}>
                                <Pagination
                                    count={pagesCount}
                                    color="primary"
                                    shape="rounded"
                                    boundaryCount={2}
                                    page={page}
                                    onChange={onPageChange}
                                />
                            </Box>
                        )}
                    </div>
                )
            )}

            <DeleteAnalysisDialog
                open={deleteAnalysisDialogOpen}
                close={closeDeleteAnalysisDialog}
                removeAnalysis={removeAnalysis}
            />
        </Container>
    );
}

export default Analyses;
