const apiUrl = process.env.REACT_APP_BASE_API_URL;

export const API_CONFIG = {
    login: apiUrl + 'users/login',
    users: apiUrl + 'users/',
    activateAccount: apiUrl + 'users/activate-account/',
    forgotPassword: apiUrl + 'users/forgot-password/',
    resetPassword: apiUrl + 'users/reset-password/',
    changePassword: apiUrl + 'users/change-password/',
    experts: apiUrl + 'experts/',
    expertsList: apiUrl + 'experts-full-name/',
    interviews: apiUrl + 'interviews/',
    analyses: apiUrl + 'analysis/',
    reports: apiUrl + 'reports/',
    getDocHtml: apiUrl + 'interviews/document-to-html/',
    downloadAnalysis: apiUrl + 'analysis/download/',
};
