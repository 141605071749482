import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Card,
    Button,
    Box,
    Tooltip,
    Breadcrumbs,
    Typography,
    Link as LinkElement,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import FormElement from '../shared/FormElement';
import Loader from '../shared/Loader';
import { getAnalysis, clearAnalysis, editAnalysis } from '../../redux/analyses/analysisActions';

function EditAnalysis(props) {
    const dispatch = useDispatch();
    const formRef = useRef();
    const analysisCreating = useSelector((state) => state.analyses.analysisCreating);
    const analysis = useSelector((state) => state.analyses.analysis);
    const analysisError = useSelector((state) => state.analyses.analysisError);
    const history = props.history;
    const id = props.match.params.id;

    useEffect(() => {
        dispatch(getAnalysis(id));

        return () => {
            dispatch(clearAnalysis());
        };
    }, [dispatch, id]);

    useHotkeys('esc', (e) => {
        e.preventDefault();
        props.history.push('/analysis');
    });

    useHotkeys('ctrl+s', (e) => {
        e.preventDefault();
        formRef.current.submitForm();
    });

    const initialValues = {
        company_name: ' ',
        headline: ' ',
    };

    const analysisValues = analysis
        ? {
              company_name: analysis.company_name,
              headline: analysis.headline,
          }
        : null;

    const defaultValues = analysisValues ? analysisValues : initialValues;

    const validationSchema = Yup.object({
        company_name: Yup.string(),
        headline: Yup.string().required('This field is required'),
    });

    const onSubmit = (values) => {
        let { company_name, headline } = values;
        let data = { company_name, headline };
        dispatch(editAnalysis(id, data, history));
    };

    return (
        <>
            <Container maxWidth="md" className="mt-2">
                <Breadcrumbs separator="›" className="mb-3">
                    <LinkElement component={Link} to="/analysis" color="primary">
                        Analysis
                    </LinkElement>
                    <Typography color="textPrimary">Edit Analysis</Typography>
                </Breadcrumbs>

                <Card className="px-8 pt-6 pb-8">
                    {analysisError ? (
                        <Alert severity="error" className="bordered">
                            Something went wrong!
                        </Alert>
                    ) : (
                        <Formik
                            innerRef={formRef}
                            initialValues={defaultValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            enableReinitialize={true}
                        >
                            {(formik) => {
                                return (
                                    <Form className="mt-6">
                                        <div className="mb-6">
                                            <FormElement
                                                control="input"
                                                label="Company"
                                                name="company_name"
                                                size="small"
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <FormElement
                                                control="input"
                                                label="Analysis Headline"
                                                name="headline"
                                                size="small"
                                            />
                                        </div>

                                        <Box className="text-center mt-3" textAlign="center">
                                            <Tooltip title="esc" placement="top">
                                                <Button
                                                    component={Link}
                                                    to="/analysis"
                                                    variant="outlined"
                                                    color="primary"
                                                    className="text-capitalize mx-2 width-md"
                                                >
                                                    Cancel
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="ctr + s" placement="top">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    className="text-capitalize mx-2 width-md"
                                                    formNoValidate
                                                    // disabled={!formik.isValid || formik.isSubmitting}
                                                >
                                                    Save
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </Card>
            </Container>

            {analysisCreating && <Loader />}
        </>
    );
}

export default EditAnalysis;
