import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { API_CONFIG } from '../../config';
import {
    SET_USERS,
    SET_USERS_ERROR,
    CLEAR_USERS,
    SET_USER,
    CLEAR_USER,
    SET_USER_ERROR,
} from '../actionTypes';

// Get all users
export const getUsers = (page, searchValue) => {
    return (dispatch) => {
        axios
            .get(API_CONFIG.users, {
                params: {
                    page: page,
                    search: searchValue,
                },
            })
            .then((response) => {
                const users = response.data.results;
                const usersCount = response.data.count;
                dispatch(setUsers(users, usersCount, page, searchValue));
            })
            .catch((error) => {
                dispatch(setUsersError(error));
            });
    };
};

// Set users
export const setUsers = (users, usersCount, page, searchValue) => {
    return { type: SET_USERS, users, usersCount, page, searchValue };
};

export const setUsersError = (error) => {
    return { type: SET_USERS_ERROR, error };
};

export const clearUsers = () => {
    return { type: CLEAR_USERS };
};

// Create a user
export const createUser = (data, history) => {
    return (dispatch) => {
        return axios
            .post(API_CONFIG.users, {
                ...data,
            })
            .then((response) => {
                if (response.status && response.status === 201) {
                    history.push('/users');
                }
            })
            .catch((error) => {
                toastr.error('', 'Something went wrong.');
                return error.response;
            });
    };
};

// Delete a user
export const deleteUser = (id) => {
    return (dispatch, getState) => {
        let page = getState().users.activePage;
        let searchValue = getState().users.searchValue;
        axios
            .delete(`${API_CONFIG.users}${id}/`)
            .then((response) => {
                dispatch(getUsers(page, searchValue));
            })
            .catch((error) => {
                console.error(error);
                toastr.error('', 'Something went wrong.');
            });
    };
};

// Get user with id
export const getUser = (id) => {
    return (dispatch) => {
        axios
            .get(`${API_CONFIG.users}${id}/`)
            .then((response) => {
                let user = response.data;
                dispatch(setUser(user));
            })
            .catch((error) => {
                console.error(error);
                dispatch(setUserError(error));
            });
    };
};

// Set user object data
export const setUser = (user) => {
    return (dispatch) => {
        dispatch({ type: SET_USER, user });
    };
};

export const setUserError = (error) => {
    return { type: SET_USER_ERROR, error };
};

// Clear user object
export const clearUser = () => {
    return { type: CLEAR_USER };
};

// Edit user
export const editUser = (id, data, history) => {
    return (dispatch) => {
        axios
            .patch(`${API_CONFIG.users}${id}/`, {
                ...data,
            })
            .then((response) => {
                history.push('/users');
            })
            .catch((error) => {
                console.error(error);
                toastr.error('', 'Something went wrong.');
            });
    };
};
