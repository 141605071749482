import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import MainLayoutRoute from './helpers/MainLayoutRoute';
// Home
import Home from './components/home/Home';
// Auth
import Auth from './components/auth/Auth';
import Login from './components/auth/Login';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import ChangePassword from './components/auth/ChangePassword';
// Analysis
import Analyses from './components/analyses/Analyses';
import AddAnalysis from './components/analyses/AddAnalysis';
import EditAnalysis from './components/analyses/EditAnalysis';
// Experts
import Experts from './components/experts/Experts';
import Expert from './components/experts/Expert';
import AddExpert from './components/experts/AddExpert';
import EditExpert from './components/experts/EditExpert';
// Interviews
import Interviews from './components/interviews/Interviews';
import Interview from './components/interviews/Interview';
import AddInterview from './components/interviews/AddInterview';
import EditInterview from './components/interviews/EditInterview';
// Reports
// import Reports from './components/report/Reports';
// Users
import Users from './components/users/Users';
import AddUser from './components/users/AddUser';
import EditUser from './components/users/EditUser';

const theme = createMuiTheme({
    typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(', '),
    },
    palette: {
        primary: {
            main: '#1a8ad4',
        },
        secondary: {
            main: '#333',
        },
        error: {
            main: '#e00f00',
            //   contrastText: '#fff',
        },
        // white: {
        //     main: '#fff',
        //     contrastText: '#ccc',
        // },
        background: {
            default: '#efefef',
        },
    },
    overrides: {
        // MuiButtonBase: {
        //     root: {
        //         '&$disabled': {
        //             color: theme.palette.action.disabled,
        //         },
        //     },
        // },
        //     MuiOutlinedInput: {
        //         root: {
        //             '&&& $input': {
        //                 padding: '15px',
        //             },
        //         },
        //     },
    },
});

function App() {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const isAdmin = useSelector((state) => state.auth.isAdmin);
    const activateEnabled = useSelector((state) => state.auth.activateEnabled);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <main className="main">
                <Auth />
                {isLoggedIn ? (
                    activateEnabled ? (
                        <Switch>
                            <Route exact path="/change-password" component={ChangePassword} />
                            <Redirect to="/change-password" />
                        </Switch>
                    ) : (
                        <Switch>
                            <MainLayoutRoute
                                exact
                                path="/analysis"
                                title="Analysis"
                                component={Analyses}
                            />
                            {isAdmin && (
                                <MainLayoutRoute
                                    exact
                                    path="/analysis/add"
                                    title="Add Analysis"
                                    component={AddAnalysis}
                                />
                            )}
                            {isAdmin && (
                                <MainLayoutRoute
                                    exact
                                    path="/analysis/edit/:id"
                                    title="Edit Analysis"
                                    component={EditAnalysis}
                                />
                            )}
                            <MainLayoutRoute
                                exact
                                path="/experts"
                                title="Experts"
                                component={Experts}
                            />
                            {isAdmin && (
                                <MainLayoutRoute
                                    exact
                                    path="/experts/add"
                                    title="Add Expert"
                                    component={AddExpert}
                                />
                            )}
                            {isAdmin && (
                                <MainLayoutRoute
                                    exact
                                    path="/experts/edit/:id"
                                    title="Edit Expert"
                                    component={EditExpert}
                                />
                            )}
                            <MainLayoutRoute
                                exact
                                path="/experts/:id"
                                title="Expert"
                                component={Expert}
                            />
                            <MainLayoutRoute
                                exact
                                path="/interviews"
                                title="Interviews"
                                component={Interviews}
                            />
                            {isAdmin && (
                                <MainLayoutRoute
                                    exact
                                    path="/interviews/add"
                                    title="Add Interview"
                                    component={AddInterview}
                                />
                            )}
                            {isAdmin && (
                                <MainLayoutRoute
                                    exact
                                    path="/interviews/edit/:id"
                                    title="Edit Interview"
                                    component={EditInterview}
                                />
                            )}
                            <MainLayoutRoute
                                exact
                                path="/interviews/:id"
                                title="Interview"
                                component={Interview}
                            />
                            {/* <MainLayoutRoute
                                exact
                                path="/reports"
                                title="Reports"
                                component={Reports}
                            /> */}
                            {isAdmin && (
                                <MainLayoutRoute
                                    exact
                                    path="/users"
                                    title="Users"
                                    component={Users}
                                />
                            )}
                            {isAdmin && (
                                <MainLayoutRoute
                                    exact
                                    path="/users/add"
                                    title="Add User"
                                    component={AddUser}
                                />
                            )}
                            {isAdmin && (
                                <MainLayoutRoute
                                    exact
                                    path="/users/edit/:id"
                                    title="Edit User"
                                    component={EditUser}
                                />
                            )}
                            <Redirect to="/analysis" />
                        </Switch>
                    )
                ) : (
                    <Switch>
                        <Route exact path="/forgot-password" component={ForgotPassword} />
                        <Route
                            exact
                            path="/reset-password/:reset_token"
                            component={ResetPassword}
                        />
                        <Route exact path="/" component={Home} />
                        <Route exact path="/sign-in" component={Login} />
                        <Route exact path="/activate-account/:activate_token" component={Login} />
                        <Redirect to="/" />
                    </Switch>
                )}
                {/* <Route component={NotFound} /> */}
            </main>
        </ThemeProvider>
    );
}

export default App;
