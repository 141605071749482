import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { API_CONFIG } from '../../config';
import {
    SET_INTERVIEWS,
    SET_INTERVIEWS_ERROR,
    CLEAR_INTERVIEWS,
    SET_INTERVIEW,
    CLEAR_INTERVIEW,
    SET_INTERVIEW_ERROR,
    SET_INTERVIEW_CREATING,
} from '../actionTypes';

// Get all interviews
export const getInterviews = (page, searchValue) => {
    return (dispatch) => {
        axios
            .get(API_CONFIG.interviews, {
                params: {
                    page: page,
                    search: searchValue,
                },
            })
            .then((response) => {
                const interviews = response.data.results;
                const interviewsCount = response.data.count;
                dispatch(setInterviews(interviews, interviewsCount, page, searchValue));
            })
            .catch((error) => {
                dispatch(setInterviewsError(error));
            });
    };
};

// Set interviews
export const setInterviews = (interviews, interviewsCount, page, searchValue) => {
    return { type: SET_INTERVIEWS, interviews, interviewsCount, page, searchValue };
};

export const setInterviewsError = (error) => {
    return { type: SET_INTERVIEWS_ERROR, error };
};

export const clearInterviews = () => {
    return { type: CLEAR_INTERVIEWS };
};

// Create an interview
export const createInterview = (data, history, redirectPath) => {
    return (dispatch) => {
        dispatch(setInterviewCreating(true));
        return axios
            .post(API_CONFIG.interviews, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((response) => {
                if (response.status && response.status === 201) {
                    if (redirectPath === 'interviews') {
                        history.push('/interviews');
                    } else if (redirectPath === 'interview') {
                        let interview_id = response.data.id;
                        history.push(`/interviews/${interview_id}`);
                    }
                }
                dispatch(setInterviewCreating(false));
            })
            .catch((error) => {
                dispatch(setInterviewCreating(false));
                toastr.error('', 'Something went wrong.');
                return error.response;
            });
    };
};

// Set interview creating
export const setInterviewCreating = (value) => {
    return { type: SET_INTERVIEW_CREATING, value };
};

// Delete an interview
export const deleteInterview = (id) => {
    return (dispatch, getState) => {
        let page = getState().interviews.activePage;
        let searchValue = getState().interviews.searchValue;
        axios
            .delete(`${API_CONFIG.interviews}${id}/`)
            .then((response) => {
                dispatch(getInterviews(page, searchValue));
            })
            .catch((error) => {
                console.error(error);
                toastr.error('', 'Something went wrong.');
            });
    };
};

// Get interview with id
export const getInterview = (id) => {
    return (dispatch) => {
        axios
            .get(`${API_CONFIG.interviews}${id}/`)
            .then((response) => {
                let interview = response.data;
                dispatch(setInterview(interview));
            })
            .catch((error) => {
                dispatch(setInterviewError(error));
            });
    };
};

// Set interview object data
export const setInterview = (interview) => {
    return (dispatch) => {
        dispatch({ type: SET_INTERVIEW, interview });
    };
};

export const setInterviewError = (error) => {
    return { type: SET_INTERVIEW_ERROR, error };
};

// Clear interview object
export const clearInterview = () => {
    return { type: CLEAR_INTERVIEW };
};

// Edit interview
export const editInterview = (id, data, history) => {
    return (dispatch) => {
        axios
            .patch(`${API_CONFIG.interviews}${id}/`, {
                ...data,
            })
            .then((response) => {
                history.push('/interviews');
            })
            .catch((error) => {
                console.error(error);
                toastr.error('', 'Something went wrong.');
            });
    };
};

// Get document file html content
export const getDocHtml = (data) => {
    return (dispatch) => {
        dispatch(setInterviewCreating(true));
        return axios
            .post(API_CONFIG.getDocHtml, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((response) => {
                dispatch(setInterviewCreating(false));
                return response;
            })
            .catch((error) => {
                dispatch(setInterviewCreating(false));
                return error.response;
            });
    };
};
