import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { Assessment, Message, PermContactCalendar, People } from '@material-ui/icons';

const sidebarNavigation = [
    {
        label: 'Analysis',
        icon: <Assessment />,
        path: '/analysis',
    },
    {
        label: 'Interviews',
        icon: <Message />,
        path: '/interviews',
    },
    {
        label: 'Experts',
        icon: <PermContactCalendar />,
        path: '/experts',
    },
    // {
    //     label: 'Reports',
    //     icon: <Description />,
    //     path: '/reports',
    // },
];

function Sidebar() {
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    return (
        <aside className="sidebar">
            <Divider />
            <List>
                {sidebarNavigation.map((item, index) => (
                    <li key={index}>
                        <NavLink
                            to={item.path}
                            exact
                            activeClassName="active"
                            className="display-block"
                        >
                            <ListItem button>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.label} />
                            </ListItem>
                        </NavLink>
                    </li>
                ))}
                {isAdmin && (
                    <li>
                        <NavLink
                            to="/users"
                            exact
                            activeClassName="active"
                            className="display-block"
                        >
                            <ListItem button>
                                <ListItemIcon>
                                    <People />
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItem>
                        </NavLink>
                    </li>
                )}
            </List>
        </aside>
    );
}

export default Sidebar;
