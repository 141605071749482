import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Container,
    Card,
    Box,
    Typography,
    Divider,
    CircularProgress,
    Breadcrumbs,
    Link as LinkElement,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { format, parseISO } from 'date-fns';
import StarRating from '../shared/StarRating';
import { getExpert, clearExpert } from '../../redux/experts/expertActions';

function Expert(props) {
    const dispatch = useDispatch();
    const expert = useSelector((state) => state.experts.expert);
    const expertLoading = useSelector((state) => state.experts.expertLoading);
    const expertError = useSelector((state) => state.experts.expertError);
    const id = props.match.params.id;

    useEffect(() => {
        dispatch(getExpert(id));

        return () => {
            dispatch(clearExpert());
        };
    }, [dispatch, id]);

    return (
        <Container maxWidth="md" className="mt-2">
            <Breadcrumbs separator="›" className="mb-3">
                <LinkElement component={Link} to="/experts" color="primary">
                    Experts
                </LinkElement>
                <Typography color="textPrimary">Expert Details</Typography>
            </Breadcrumbs>

            <Card className="px-8 pt-6 pb-8">
                {expertLoading ? (
                    <Box display="flex" justifyContent="center" p={4}>
                        <CircularProgress size={50} />
                    </Box>
                ) : expertError ? (
                    <Alert severity="error" className="bordered">
                        Something went wrong!
                    </Alert>
                ) : (
                    expert && (
                        <>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h4" color="primary">
                                    {expert.name} {expert.surname}
                                </Typography>
                                <StarRating val={expert.rating} />
                            </Box>

                            <div className="mt-5">
                                <Typography variant="h6" color="secondary" className="mb-1">
                                    Bio
                                </Typography>
                                <Typography variant="body2" component="div">
                                    <pre>{expert.bio}</pre>
                                </Typography>
                            </div>

                            <Divider className="my-5" />

                            <div className="mt-5">
                                <Typography variant="h6" color="secondary" className="mb-1">
                                    Timeline
                                </Typography>
                                <ul className="bulleted-list">
                                    {expert.timeline &&
                                        expert.timeline.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                </ul>
                            </div>
                            <Divider className="my-5" />

                            <div className="mt-5">
                                <Typography variant="h6" color="secondary" className="mb-1">
                                    Primary Companies
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {expert.primary_companies}
                                </Typography>
                            </div>

                            <Divider className="my-5" />

                            <div className="mt-5">
                                <Typography variant="h6" color="secondary" className="mb-1">
                                    Secondary Companies
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {expert.secondary_companies}
                                </Typography>
                            </div>

                            <Box fontSize={14} fontStyle="italic" mt={5} textAlign="right">
                                <Typography color="textSecondary">
                                    Date added: {format(parseISO(expert.created), 'yyyy-dd-MM')}
                                </Typography>
                            </Box>
                        </>
                    )
                )}
            </Card>
        </Container>
    );
}

export default Expert;
