import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Card,
    Button,
    Box,
    Grid,
    Breadcrumbs,
    Typography,
    Link as LinkElement,
    Tooltip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import FormElement from '../shared/FormElement';
import { getExpert, clearExpert, editExpert } from '../../redux/experts/expertActions';

function EditExpert(props) {
    const dispatch = useDispatch();
    const formRef = useRef();
    const expert = useSelector((state) => state.experts.expert);
    const expertError = useSelector((state) => state.experts.expertError);
    const history = props.history;
    const id = props.match.params.id;

    useEffect(() => {
        dispatch(getExpert(id));

        return () => {
            dispatch(clearExpert());
        };
    }, [dispatch, id]);

    useHotkeys('esc', (e) => {
        e.preventDefault();
        props.history.push('/experts');
    });

    useHotkeys('ctrl+s', (e) => {
        e.preventDefault();
        formRef.current.submitForm();
    });

    const initialValues = {
        name: ' ',
        surname: ' ',
        rating: ' ',
        bio: ' ',
        timeline: [''],
        primary_companies: ' ',
        secondary_companies: ' ',
    };

    const expertValues = expert
        ? {
              name: expert.name,
              surname: expert.surname,
              rating: expert.rating,
              bio: expert.bio,
              timeline: expert.timeline,
              primary_companies: expert.primary_companies,
              secondary_companies: expert.secondary_companies,
          }
        : null;

    const defaultValues = expertValues ? expertValues : initialValues;

    const validationSchema = Yup.object({
        name: Yup.string().required('This field is required'),
        surname: Yup.string().required('This field is required'),
        rating: Yup.number()
            .max(10, 'Maximum value is 10')
            .typeError('Please enter a valid number')
            .integer('Please enter a valid number')
            .positive('Please enter a positive number')
            .required('This field is required'),
        bio: Yup.string().required('This field is required'),
        timeline: Yup.array()
            .required('This field is required')
            .of(Yup.string().required("Timeline can't be empty")),
        primary_companies: Yup.string().required('This field is required'),
        secondary_companies: Yup.string().required('This field is required'),
    });

    const onSubmit = (values) => {
        let {
            name,
            surname,
            rating,
            bio,
            timeline,
            primary_companies,
            secondary_companies,
        } = values;
        let data = { name, surname, rating, bio, timeline, primary_companies, secondary_companies };
        dispatch(editExpert(id, data, history));
    };

    return (
        <Container maxWidth="md" className="mt-2">
            <Breadcrumbs separator="›" className="mb-3">
                <LinkElement component={Link} to="/experts" color="primary">
                    Experts
                </LinkElement>
                <Typography color="textPrimary">Edit Expert</Typography>
            </Breadcrumbs>

            <Card className="px-8 pt-6 pb-8">
                {expertError ? (
                    <Alert severity="error" className="bordered">
                        Something went wrong!
                    </Alert>
                ) : (
                    <Formik
                        innerRef={formRef}
                        initialValues={defaultValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                    >
                        {(formik) => {
                            return (
                                <Form className="mt-6">
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <div className="mb-1">
                                                <FormElement
                                                    control="input"
                                                    label="Name"
                                                    name="name"
                                                    size="small"
                                                />
                                            </div>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <div className="mb-1">
                                                <FormElement
                                                    control="input"
                                                    label="Surname"
                                                    name="surname"
                                                    size="small"
                                                />
                                            </div>
                                        </Grid>

                                        <Grid item md={3} xs={6}>
                                            <div className="mb-6">
                                                <FormElement
                                                    control="input"
                                                    label="Rating (1-10)"
                                                    name="rating"
                                                    size="small"
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <div className="mb-6">
                                        <FormElement
                                            control="textarea"
                                            label="Detailed Bio"
                                            name="bio"
                                            rows={6}
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <FormElement
                                            control="list"
                                            label="Timeline"
                                            name="timeline"
                                            form={formik}
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <FormElement
                                            control="input"
                                            label="Primary Companies"
                                            name="primary_companies"
                                            size="small"
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <FormElement
                                            control="input"
                                            label="Secondary Companies"
                                            name="secondary_companies"
                                            size="small"
                                        />
                                    </div>

                                    <Box className="text-center mt-3" textAlign="center">
                                        <Tooltip title="esc" placement="top">
                                            <Button
                                                component={Link}
                                                to="/experts"
                                                variant="outlined"
                                                color="primary"
                                                className="text-capitalize mx-2 width-md"
                                            >
                                                Cancel
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="ctr + s" placement="top">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                className="text-capitalize mx-2 width-md"
                                                formNoValidate
                                                // disabled={!formik.isValid || formik.isSubmitting}
                                            >
                                                Save
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Card>
        </Container>
    );
}

export default EditExpert;
