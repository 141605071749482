import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function Select(props) {
    const [inputValue, setInputValue] = useState('');
    const { name, options, labelName, noOptionsText, onChangeEvent, ...rest } = props;

    return (
        <div className="form-element">
            <Field name={name}>
                {({ field, form }) => {
                    return (
                        <>
                            <Autocomplete
                                name={name}
                                value={form.values[name]}
                                options={options}
                                noOptionsText={noOptionsText}
                                onChange={(event, newValue) => {
                                    form.setFieldValue(name, newValue);
                                    onChangeEvent(newValue);
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                // renderOption={(option) => option[labelName]}
                                getOptionLabel={(option) => (option ? option[labelName] : '')}
                                getOptionSelected={(option, value) =>
                                    option[labelName] === value[labelName]
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        fullWidth
                                        error={form.touched[name] && Boolean(form.errors[name])}
                                        helperText={form.touched[name] && form.errors[name]}
                                        onBlur={() => form.setFieldTouched(name, true)}
                                        {...rest}
                                    />
                                )}
                            />
                        </>
                    );
                }}
            </Field>
        </div>
    );
}

Select.propTypes = {
    name: PropTypes.string,
    options: PropTypes.array,
    label: PropTypes.string,
    labelName: PropTypes.string,
    size: PropTypes.string,
    noOptionsText: PropTypes.string,
    onChangeEvent: PropTypes.func,
};

export default Select;
