import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authReducer from './auth/authReducer';
import userReducer from './users/userReducer';
import expertReducer from './experts/expertReducer';
import interviewReducer from './interviews/interviewReducer';
import analysisReducer from './analyses/analysisReducer';

const rootReducer = combineReducers({
    toastr: toastrReducer,
    auth: authReducer,
    users: userReducer,
    experts: expertReducer,
    interviews: interviewReducer,
    analyses: analysisReducer,
});

export default rootReducer;
