import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Card,
    Button,
    Box,
    Tooltip,
    Breadcrumbs,
    Typography,
    Link as LinkElement,
} from '@material-ui/core';
import FormElement from '../shared/FormElement';
import Loader from '../shared/Loader';
import { createAnalysis } from '../../redux/analyses/analysisActions';

function AddAnalysis(props) {
    const dispatch = useDispatch();
    const formRef = useRef();
    const analysisCreating = useSelector((state) => state.analyses.analysisCreating);

    useHotkeys('esc', (e) => {
        e.preventDefault();
        props.history.push('/analysis');
    });

    useHotkeys('ctrl+s', (e) => {
        e.preventDefault();
        formRef.current.submitForm();
    });

    const initialValues = {
        company_name: '',
        headline: '',
        pdf_file: '',
    };

    const validationSchema = Yup.object({
        company_name: Yup.string(),
        headline: Yup.string().required('This field is required'),
        pdf_file: Yup.mixed()
            .required('This field is required')
            .test('fileFormat', 'Unsupported file format. Please upload .pdf file', (value) => {
                return value && ['application/pdf'].includes(value.type);
            }),
    });

    const onSubmit = async (values, { setErrors }) => {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
            formData.append(key, values[key]);
        });
        const res = await dispatch(createAnalysis(formData, props.history));
        console.log(res);

        // if (res && res.status === 500 && res.data && res.data.indexOf('too long') > -1) {
        //     let message = 'File is too long';
        //     setErrors({ file_size: message });
        // }
    };

    const fileChanged = (file) => {
        if (file) {
            const fileName = file.name.split('.').slice(0, -1).join('.');
            formRef.current.setFieldValue('headline', fileName);
            formRef.current.setFieldTouched('headline', false);
            formRef.current.setFieldError('headline', null);
        }
    };

    return (
        <>
            <Container maxWidth="md" className="mt-2">
                <Breadcrumbs separator="›" className="mb-3">
                    <LinkElement component={Link} to="/analysis" color="primary">
                        Analysis
                    </LinkElement>
                    <Typography color="textPrimary">Add Analysis</Typography>
                </Breadcrumbs>

                <Card className="px-8 pt-6 pb-8">
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik) => {
                            return (
                                <Form className="mt-6">
                                    <div className="mb-6">
                                        <FormElement
                                            control="input"
                                            label="Company"
                                            name="company_name"
                                            size="small"
                                        />
                                    </div>

                                    {formik.values.pdf_file && (
                                        <div className="mb-6">
                                            <FormElement
                                                control="input"
                                                label="Analysis Headline"
                                                name="headline"
                                                size="small"
                                            />
                                        </div>
                                    )}

                                    <div className="mb-6">
                                        <FormElement
                                            accept="application/pdf"
                                            control="fileupload"
                                            label="Upload Analysis"
                                            name="pdf_file"
                                            fileChanged={fileChanged}
                                        />
                                    </div>

                                    {/* {formik.errors.file_size && (
                                        <p className="form-error">{formik.errors.file_size}</p>
                                    )} */}

                                    <Box className="text-center mt-3" textAlign="center">
                                        <Tooltip title="esc" placement="top">
                                            <Button
                                                component={Link}
                                                to="/analysis"
                                                variant="outlined"
                                                color="primary"
                                                className="text-capitalize mx-2 width-md"
                                            >
                                                Cancel
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="ctr + s" placement="top">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                className="text-capitalize mx-2 width-md"
                                                formNoValidate
                                                // disabled={!formik.isValid || formik.isSubmitting}
                                            >
                                                Save
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </Form>
                            );
                        }}
                    </Formik>
                </Card>
            </Container>

            {analysisCreating && <Loader />}
        </>
    );
}

export default AddAnalysis;
