import {
    SET_CURRENT_USER,
    LOGOUT,
    FORGOT_PASSWORD_SENT,
    RESET_PASSWORD_SENT,
    ACTIVATE_ENABLED,
} from '../actionTypes';

const initialState = {
    user: null,
    // loading: true,
    error: '',
    isLoggedIn: sessionStorage.getItem('token') ? true : false,
    isAdmin: sessionStorage.getItem('user_role') === 'admin' ? true : false,
    activateEnabled: localStorage.getItem('activate_enabled') === 'true' ? true : false,
    forgotPasswordSent: false,
    resetPasswordSent: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                user: action.user,
                // loading: false,
                error: '',
                isLoggedIn: true,
                isAdmin: action.user.role === 'admin',
            };
        case LOGOUT:
            return {
                ...initialState,
                isLoggedIn: false,
                isAdmin: '',
            };
        case FORGOT_PASSWORD_SENT:
            return {
                ...state,
                forgotPasswordSent: action.value,
            };
        case RESET_PASSWORD_SENT:
            return {
                ...state,
                resetPasswordSent: action.value,
            };
        case ACTIVATE_ENABLED:
            return {
                ...state,
                activateEnabled: action.value,
            };
        default:
            return state;
    }
};

export default authReducer;
