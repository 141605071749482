import React, { useState, useEffect, useCallback } from 'react';
import { Route } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, Drawer, AppBar, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Header from '../components/elements/Header';
import Sidebar from '../components/elements/Sidebar';
import Footer from '../components/elements/Footer';

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1, 0, 2),
        ...theme.mixins.toolbar,
    },
    content: {
        width: `calc(100% - ${drawerWidth}px)`,
        padding: theme.spacing(3, 3, 7, 3),
        minHeight: '100vh',
        position: 'relative',
    },
}));

function MainLayoutRoute({ title, component: Component, ...rest }) {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [drawerBtnVidible, setDrawerBtnVidible] = useState(false);

    const onResize = useCallback(() => {
        let windowWidth =
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (windowWidth <= 1024) {
            handleDrawerClose();
            setDrawerBtnVidible(true);
        } else {
            handleDrawerOpen();
            setDrawerBtnVidible(false);
        }
    }, []);

    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [onResize]);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const mainClassname = drawerOpen ? 'main-wrap' : 'main-wrap main-wrap-mini';

    return (
        <div className={`${mainClassname} ${classes.root}`}>
            <AppBar
                position="fixed"
                className={
                    'header ' +
                    clsx(classes.appBar, {
                        [classes.appBarShift]: drawerOpen,
                    })
                }
            >
                <Toolbar>
                    {!drawerBtnVidible && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: drawerOpen,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}

                    <Header title={title} />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: drawerOpen,
                    [classes.drawerClose]: !drawerOpen,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen,
                    }),
                }}
            >
                <div className={`sidebar-top ${classes.toolbar}`}>
                    <div className="sidebar-logo">
                        <img src="/images/logo.svg" alt="Tegus" />
                    </div>
                    <IconButton onClick={handleDrawerClose} color="inherit">
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Sidebar />
            </Drawer>
            <div className={`main-content ${classes.content}`}>
                <div className={classes.toolbar} />
                <Route
                    {...rest}
                    render={(props) => (
                        <>
                            <Component {...props} />
                            <Footer />
                        </>
                    )}
                />
            </div>
        </div>
    );
}

export default MainLayoutRoute;
