import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { API_CONFIG } from '../../config';
import {
    SET_ANALYSES,
    SET_ANALYSES_ERROR,
    CLEAR_ANALYSES,
    SET_ANALYSIS,
    CLEAR_ANALYSIS,
    SET_ANALYSIS_ERROR,
    SET_ANALYSIS_CREATING,
} from '../actionTypes';

// Get all analyses
export const getAnalyses = (page, searchValue) => {
    return (dispatch) => {
        axios
            .get(API_CONFIG.analyses, {
                params: {
                    page: page,
                    search: searchValue,
                },
            })
            .then((response) => {
                const analyses = response.data.results;
                const analysesCount = response.data.count;
                dispatch(setAnalyses(analyses, analysesCount, page, searchValue));
            })
            .catch((error) => {
                dispatch(setAnalysesError(error));
            });
    };
};

// Set analyses
export const setAnalyses = (analyses, analysesCount, page, searchValue) => {
    return { type: SET_ANALYSES, analyses, analysesCount, page, searchValue };
};

export const setAnalysesError = (error) => {
    return { type: SET_ANALYSES_ERROR, error };
};

export const clearAnalyses = () => {
    return { type: CLEAR_ANALYSES };
};

// Create an analysis
export const createAnalysis = (data, history) => {
    return (dispatch) => {
        dispatch(setAnalysisCreating(true));
        return axios
            .post(API_CONFIG.analyses, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((response) => {
                if (response.status && response.status === 201) {
                    history.push('/analysis');
                }
                dispatch(setAnalysisCreating(false));
            })
            .catch((error) => {
                dispatch(setAnalysisCreating(false));
                toastr.error('', 'Something went wrong.');
                return error.response;
            });
    };
};

// Set analysis creating
export const setAnalysisCreating = (value) => {
    return { type: SET_ANALYSIS_CREATING, value };
};

// Delete an analysis
export const deleteAnalysis = (id) => {
    return (dispatch, getState) => {
        let page = getState().analyses.activePage;
        let searchValue = getState().analyses.searchValue;
        axios
            .delete(`${API_CONFIG.analyses}${id}/`)
            .then((response) => {
                dispatch(getAnalyses(page, searchValue));
            })
            .catch((error) => {
                console.error(error);
                toastr.error('', 'Something went wrong.');
            });
    };
};

// Get analysis with id
export const getAnalysis = (id) => {
    return (dispatch) => {
        axios
            .get(`${API_CONFIG.analyses}${id}/`)
            .then((response) => {
                let analysis = response.data;
                dispatch(setAnalysis(analysis));
            })
            .catch((error) => {
                dispatch(setAnalysisError(error));
            });
    };
};

// Set analysis object data
export const setAnalysis = (analysis) => {
    return (dispatch) => {
        dispatch({ type: SET_ANALYSIS, analysis });
    };
};

export const setAnalysisError = (error) => {
    return { type: SET_ANALYSIS_ERROR, error };
};

// Clear analysis object
export const clearAnalysis = () => {
    return { type: CLEAR_ANALYSIS };
};

// Edit analysis
export const editAnalysis = (id, data, history) => {
    return (dispatch) => {
        axios
            .patch(`${API_CONFIG.analyses}${id}/`, {
                ...data,
            })
            .then((response) => {
                history.push('/analysis');
            })
            .catch((error) => {
                console.error(error);
                toastr.error('', 'Something went wrong.');
            });
    };
};

// Download PDF file
export const downloadAnalysis = (id) => {
    return (dispatch) => {
        axios
            .get(`${API_CONFIG.downloadAnalysis}${id}/`, {
                responseType: 'blob',
            })
            .then((response) => {
                const fileName = response.headers['content-disposition'].split('=')[1];
                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error(error);
                toastr.error('', 'Something went wrong.');
            });
    };
};
