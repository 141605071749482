import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Card,
    Button,
    Box,
    Grid,
    Breadcrumbs,
    Typography,
    Link as LinkElement,
    Tooltip,
} from '@material-ui/core';
import FormElement from '../shared/FormElement';
import { createExpert } from '../../redux/experts/expertActions';

function AddExpert(props) {
    const dispatch = useDispatch();
    const formRef = useRef();

    useHotkeys('esc', (e) => {
        e.preventDefault();
        props.history.push('/experts');
    });

    useHotkeys('ctrl+s', (e) => {
        e.preventDefault();
        formRef.current.values.redirect_to = 'experts';
        formRef.current.submitForm();
    });

    useHotkeys('ctrl+shift+s', (e) => {
        e.preventDefault();
        formRef.current.values.redirect_to = 'add-interview';
        formRef.current.submitForm();
    });

    const initialValues = {
        name: '',
        surname: '',
        rating: '',
        bio: '',
        timeline: [''],
        primary_companies: '',
        secondary_companies: '',
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('This field is required'),
        surname: Yup.string().required('This field is required'),
        rating: Yup.number()
            .max(10, 'Maximum value is 10')
            .typeError('Please enter a valid number')
            .integer('Please enter a valid number')
            .positive('Please enter a positive number')
            .required('This field is required'),
        bio: Yup.string().required('This field is required'),
        timeline: Yup.array()
            .required('This field is required')
            .of(Yup.string().required("Timeline can't be empty")),
        primary_companies: Yup.string().required('This field is required'),
        secondary_companies: Yup.string().required('This field is required'),
    });

    const onSubmit = async (values, { setErrors }) => {
        const redirectPath = values.redirect_to;
        const res = await dispatch(createExpert(values, props.history, redirectPath));
        console.log(res);

        // if (res && res.status === 400 && res.data && res.data.email) {
        //     let message = res.data && res.data.email && res.data.email[0];
        //     setErrors({ email_unique: message });
        // }
    };

    return (
        <Container maxWidth="md" className="mt-2">
            <Breadcrumbs separator="›" className="mb-3">
                <LinkElement component={Link} to="/experts" color="primary">
                    Experts
                </LinkElement>
                <Typography color="textPrimary">Add Expert</Typography>
            </Breadcrumbs>

            <Card className="px-8 pt-6 pb-8">
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(formik) => {
                        return (
                            <Form className="mt-6">
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <div className="mb-1">
                                            <FormElement
                                                control="input"
                                                label="Name"
                                                name="name"
                                                size="small"
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <div className="mb-1">
                                            <FormElement
                                                control="input"
                                                label="Surname"
                                                name="surname"
                                                size="small"
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item md={3} xs={6}>
                                        <div className="mb-6">
                                            <FormElement
                                                control="input"
                                                label="Rating (1-10)"
                                                name="rating"
                                                size="small"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <div className="mb-6">
                                    <FormElement
                                        control="textarea"
                                        label="Detailed Bio"
                                        name="bio"
                                        rows={6}
                                    />
                                </div>

                                <div className="mb-6">
                                    <FormElement
                                        control="list"
                                        label="Timeline"
                                        name="timeline"
                                        form={formik}
                                    />
                                </div>

                                <div className="mb-6">
                                    <FormElement
                                        control="input"
                                        label="Primary Companies"
                                        name="primary_companies"
                                        size="small"
                                    />
                                </div>

                                <div className="mb-6">
                                    <FormElement
                                        control="input"
                                        label="Secondary Companies"
                                        name="secondary_companies"
                                        size="small"
                                    />
                                </div>

                                <Box className="text-center mt-3" textAlign="center">
                                    <Tooltip title="esc" placement="top">
                                        <Button
                                            component={Link}
                                            to="/experts"
                                            variant="outlined"
                                            color="primary"
                                            className="text-capitalize mx-2 width-md"
                                        >
                                            Cancel
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="ctr + s" placement="top">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className="text-capitalize mx-2 width-md"
                                            formNoValidate
                                            onClick={() =>
                                                formik.setFieldValue('redirect_to', 'experts')
                                            }
                                            // disabled={!formik.isValid || formik.isSubmitting}
                                        >
                                            Save
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="ctr + shift + s" placement="top">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className="text-capitalize mx-2 width-md"
                                            formNoValidate
                                            onClick={() =>
                                                formik.setFieldValue('redirect_to', 'add-interview')
                                            }
                                            // disabled={!formik.isValid || formik.isSubmitting}
                                        >
                                            Save &#38; Add Interview
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Card>
        </Container>
    );
}

export default AddExpert;

// lusine.stdev@yopmail.com
