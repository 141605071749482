import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Button } from '@material-ui/core';
import { logout } from '../../redux/auth/authActions';

function Header(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.auth.user);
    const userFullName = user && user.first_name + ' ' + user.last_name;

    const logoutUser = () => {
        dispatch(logout(history));
    };

    return (
        <>
            <Typography variant="h5" component="h3" className="text-nowrap mr-8">
                {props.title}
            </Typography>
            <Box display="flex" alignItems="center" className="ml-auto">
                <Typography variant="subtitle1" component="p" noWrap className="user-name">
                    {userFullName}
                </Typography>
                <Button variant="contained" color="secondary" onClick={logoutUser} className="ml-4">
                    Log out
                </Button>
            </Box>
        </>
    );
}

Header.propTypes = {
    title: PropTypes.string,
};

export default Header;
