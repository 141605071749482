import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    IconButton,
    Button,
    TextField,
    InputAdornment,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import { Edit, Delete, Add, Search, People } from '@material-ui/icons';
import { Pagination, Alert } from '@material-ui/lab';
import { format, parseISO } from 'date-fns';
import NoData from '../shared/NoData';
import DeleteUserDialog from './DeleteUserDialog';
import { getUsers, deleteUser } from '../../redux/users/userActions';

let timer = 0;
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 800,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

function Users() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [page, setPage] = useState(1);
    const users = useSelector((state) => state.users.users);
    const error = useSelector((state) => state.users.error);
    const loading = useSelector((state) => state.users.loading);
    const pagesCount = useSelector((state) => state.users.pagesCount);

    useEffect(() => {
        dispatch(getUsers(1, ''));
    }, [dispatch]);

    const openDeleteUserDialog = (index) => {
        setDeleteUserDialogOpen(true);
        setCurrentUser(index);
    };

    const closeDeleteUserDialog = () => {
        setDeleteUserDialogOpen(false);
        setCurrentUser(null);
    };

    const removeUser = () => {
        setDeleteUserDialogOpen(false);
        dispatch(deleteUser(currentUser));
        setCurrentUser(null);
    };

    const onPageChange = (e, activePage) => {
        if (page !== activePage) {
            setPage(activePage);
            dispatch(getUsers(activePage, searchValue));
        }
    };

    const searchUsers = (e) => {
        let value = e.target.value;
        const delay = 300;
        setSearchValue(value);
        clearTimeout(timer);
        timer = setTimeout(() => {
            setPage(1);
            dispatch(getUsers(1, value));
        }, delay);
    };

    return (
        <Container className="mt-6">
            <Grid
                container
                className="mb-4"
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item xs={5}>
                    <TextField
                        label="Search"
                        type="search"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={searchValue}
                        onChange={searchUsers}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search color="disabled" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={3} className="text-right">
                    <Button
                        component={Link}
                        to="/users/add"
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                    >
                        Add User
                    </Button>
                </Grid>
            </Grid>

            {loading ? (
                <Box display="flex" justifyContent="center" p={4}>
                    <CircularProgress size={50} />
                </Box>
            ) : error ? (
                <Alert severity="error" className="bordered">
                    Something went wrong!
                </Alert>
            ) : (
                users && (
                    <div className={classes.root}>
                        <Paper className={classes.paper}>
                            <TableContainer>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="28%">User</TableCell>
                                            <TableCell width="28%">Company</TableCell>
                                            <TableCell width="34%">Email</TableCell>
                                            <TableCell width="110" className="text-nowrap">
                                                Date Added
                                            </TableCell>
                                            <TableCell className="text-nowrap">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {users.length > 0 ? (
                                            users.map((user) => (
                                                <TableRow hover key={user.id}>
                                                    <TableCell>
                                                        {user.first_name} {user.last_name}
                                                    </TableCell>
                                                    <TableCell>{user.company_name}</TableCell>
                                                    <TableCell>{user.email}</TableCell>
                                                    <TableCell className="text-nowrap">
                                                        {format(
                                                            parseISO(user.created),
                                                            'yyyy-dd-MM',
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box display="flex">
                                                            <IconButton
                                                                component={Link}
                                                                to={`/users/edit/${user.id}`}
                                                                size="small"
                                                                className="mr-2"
                                                            >
                                                                <Edit
                                                                    color="primary"
                                                                    fontSize="small"
                                                                />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    openDeleteUserDialog(user.id)
                                                                }
                                                            >
                                                                <Delete
                                                                    color="error"
                                                                    fontSize="small"
                                                                />
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={6}>
                                                    <NoData
                                                        text="No Users Found"
                                                        iconSize={60}
                                                        opcaity={30}
                                                        icon={<People fontSize="inherit" />}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        {users && users.length > 0 && (
                            <Box display="flex" justifyContent="center" mt={3}>
                                <Pagination
                                    count={pagesCount}
                                    color="primary"
                                    shape="rounded"
                                    boundaryCount={2}
                                    page={page}
                                    onChange={onPageChange}
                                />
                            </Box>
                        )}
                    </div>
                )
            )}

            <DeleteUserDialog
                open={deleteUserDialogOpen}
                close={closeDeleteUserDialog}
                removeUser={removeUser}
            />
        </Container>
    );
}

export default Users;
